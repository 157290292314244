import React, {Component} from 'react';
import {Col, Modal, ProgressBar, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

//components
import Header from '../../components/Header';
import BackButton from '../../components/buttons/BackButton';
import OutlineButton from '../../components/buttons/OutlineButton';
import OrderDetails from '../../components/serviceRequestForm/OrderDetails';
import RequestSummary from '../../components/serviceRequestForm/RequestSummary';
import CommodityCatelogueSelection from '../../components/CommodityCatelogueSelection';
import MobileHeader from '../../components/MobileHeader';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import DeliveryDetails from '../../components/serviceRequestForm/DeliveryDetails'
// import {CommodityType} from "../../components/serviceRequestForm/CommodityType";
import PaymentDetails from "../../components/serviceRequestForm/PaymentDetails";
import WarehouseDetails from "../../components/serviceRequestForm/WarehouseDetails";
import SpecialRequest from '../../components/serviceRequestForm/SpecialRequest';

//utils
import LocalStorage from "../../utilities/localStorage";
import isEmpty from 'lodash/isEmpty'

//actions
import * as commodityActions from "../../utilities/Store/actions/commodity";
import * as userActions from '../../utilities/Store/actions/user';

//images
import blackCancelIcon from '../../assets/Images/black-cancel-icon.svg';
import cart from '../../assets/Images/cart.svg';

//styles
import './styles.scss';

const isMobile = window.innerWidth < 768;

class ServiceRequestForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            requestSummary: false,
            showDeliveryDetails: false,
            showPaymentDetails: false,
            showOtherDetails: false,
            hideContinue: true,
            hideDeliveryContinue: true,
            hidePaymentContinue: true,
            errors: {},
            addresses: [],
            deliveryDetails: {},
            commodityType: 'non_perishable',
        }
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleRequestSummaryShow = this.handleRequestSummaryShow.bind(this);
        this.handleRequestSummaryClose = this.handleRequestSummaryClose.bind(this);
        this.handleShowDeliveryDetails = this.handleShowDeliveryDetails.bind(this);
        this.handleShowPaymentDetails = this.handleShowPaymentDetails.bind(this);
        this.handleShowOtherDetails = this.handleShowOtherDetails.bind(this);
        this.handleFinancialAssistanceRequest = this.handleFinancialAssistanceRequest.bind(this);
        this.handleSpecialRequest = this.handleSpecialRequest.bind(this);
        this.handleDeliveryDetailsChange = this.handleDeliveryDetailsChange.bind(this);
    }

    trackAddCommodityButtonClick() {
        if (typeof window !== 'undefined' && window.gtag != null) {
            window.gtag('set', 'click', { action: 'add_commodity_btn'})
        }
    }

    handleClose() {
        this.setState({show: false});
    }

    handleShow() {
        this.trackAddCommodityButtonClick()
        this.setState({show: true});
    }

    handleRequestSummaryClose() {
        this.setState({requestSummary: false});
    }

    handleRequestSummaryShow() {
        this.setState({requestSummary: true});
    }

    handleShowDeliveryDetails() {
        this.setState({showDeliveryDetails: true, hideContinue: false});
    }

    handleShowPaymentDetails() {
        this.setState({showPaymentDetails: true, hideDeliveryContinue: false});
    }

    handleShowOtherDetails() {
        this.setState({showOtherDetails: true, hidePaymentContinue: false});
    }

    handleFinancialAssistanceRequest(req) {
        let copyDeliveryDetails = {...this.state?.deliveryDetails}
        copyDeliveryDetails.financialAssistance = req
        this.setState({deliveryDetails:copyDeliveryDetails});
    }

    handleSpecialRequest(req) {
        let copyDeliveryDetails = {...this.state?.deliveryDetails}
        copyDeliveryDetails.specialRequest = req
        this.setState({deliveryDetails:copyDeliveryDetails});
    }

    handleDeliveryDetailsChange(deliveryDetails) {
        let copyDeliveryDetails = {...this.state?.deliveryDetails, ...deliveryDetails}
        this.setState({deliveryDetails:copyDeliveryDetails});
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].className = 'page-service-request-form';
        window.scrollTo(0, 0)
        this.updateOrderSummary()
        this.fetchLocations()
        this.fetchCommodities()
    }

    fetchCommodities = async () => {
        let res = {}
        try {
            this.setState({loading: true})
            res = await this.props.getCommodityList()
            if (res?.status === 200 && !isEmpty(res?.data?.data?.product_details)) {
                this.setState({
                    commodityList: res?.data?.data?.product_details,
                })
            }
        } catch (e) {
        }
    }

    fetchLocations = async()=>{
        let res = {}
        try{
            res = await this.props.getDeliveryAddress()
            if((res?.error === null) && !isEmpty(res?.data?.addresses)){
                this.setState({
                    addresses: res?.data?.addresses
                })
            }
        }catch(e){}
    }

    removeOrderItem = (id) => {
        if (id) {
            let selectedCommodities = this.props.requestedCommodities
            selectedCommodities = selectedCommodities?.filter(item => item.id !== id)
            this.props.updateRequestedCommodities(selectedCommodities)
            this.setState({requestedCommodities:selectedCommodities})
            LocalStorage.set.selectedCommodities(JSON.stringify(selectedCommodities))
        }
    }

    updateOrderSummary = (itemId, data) => {
        let updatedRequestedCommodities = this.props.requestedCommodities.map(item => {
            if (item.id === itemId) {
                return {...item, ...data}
            }
            return item
        })
        this.props.updateRequestedCommodities(updatedRequestedCommodities)
        this.setState({requestedCommodities:updatedRequestedCommodities})
        LocalStorage.set.selectedCommodities(JSON.stringify(updatedRequestedCommodities))
    }

    render() {
        const {requestSummary, commodityType, hideContinue, addresses, errors, show} = this.state;
        const {requestedCommodities} = this.props;
        return (
            <div className="service-request-form">
                <div className="main-header-section">
                    <Header/>
                </div>

                <MobileHeader/>

                <div className="form-section">
                    <div className="action-btn">
                        <BackButton/>
                        <div className="mobile-cart">
                            <Link to="#" onClick={this.handleRequestSummaryShow}><img className="cart" src={cart} alt="cart"/></Link>
                        </div>
                    </div>

                    {
                        requestSummary 
                        ?<div className="mobile-request-summary">
                            <div className="backdrops"/>
                                <RequestSummary partnerType={commodityType}
                                            onClick={this.handleRequestSummaryClose}/>
                        </div>
                        : null
                    }

                    <h4 className="heading">Service Request</h4>

                    <div className="progress-bars">
                        <ProgressBar now={25}/>
                    </div>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                            {/* {
                                (!isMobile || showDeliveryDetails) 
                                ? (
                                    <div className="commodity-type-section mb-4">
                                        <h6 className="sub-heading">Commodity Type</h6>
                                        <CommodityType
                                            error={errors.partnerType}
                                            handleChange={(commodityType) => this.setState({commodityType})}/>
                                    </div>
                                ) 
                                : null
                            } */}

                            <div className="order-detail-section mb-4">
                                <h6 className="sub-heading">Order Details</h6>
                                {
                                    requestedCommodities?.map(item => {
                                        return <OrderDetails
                                            {...item}
                                            errors={errors.orderErrors}
                                            removeOrderItem={this.removeOrderItem}
                                            src={item.product_image_url}
                                            updateOrderSummary={this.updateOrderSummary}
                                            key={item.id}
                                        />
                                    })
                                }
                                <OutlineButton
                                    btnText="+ Add Commodity"
                                    handleClick={this.handleShow}
                                />

                                {(isMobile && hideContinue) ? (
                                    <PrimaryButton
                                        btnText="Continue"
                                        handleClick={this.handleShowDeliveryDetails}
                                    />
                                ) : null}
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <RequestSummary updateErrors={(updateErrors) => this.setState(updateErrors)}
                                partnerType={this.state.commodityType} deliveryDetails={this.state.deliveryDetails}/>
                        </Col>
                    </Row>
                    {requestedCommodities.length>0
                        ? <>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                    <div className="delivery-detail-section mb-4">
                                        <h6 className="sub-heading">Delivery Details</h6>
                                        <DeliveryDetails addresses={addresses}
                                                        handleChange={this.handleDeliveryDetailsChange}/>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                    <div className="warehouse-assistance-section mb-4">
                                        <WarehouseDetails handleChange={this.handleDeliveryDetailsChange}/>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                    <div className="financial-assistance-section mb-4">
                                        <PaymentDetails handleChange={this.handleFinancialAssistanceRequest}/>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                    <div className="special-request-section mb-4">
                                        <SpecialRequest handleChange={this.handleSpecialRequest}/>
                                    </div>
                                </Col>
                            </Row>
                        </>
                        :<></>
                    }
                </div>

                <Modal
                    show={show}
                    onHide={this.handleClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="modal-section"
                >
                    <Modal.Header>
                        <Link to="#" onClick={this.handleClose}>
                            <img className="black-cancel-icon" src={blackCancelIcon} alt="cancel-icon"/>
                        </Link>
                    </Modal.Header>
                    <Modal.Body>
                        <CommodityCatelogueSelection
                            heading="Select Commodity"
                            subheading="Select commodities to add and continue to request."
                            description="All commodities"
                            handleClick={this.handleClose}
                        />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default connect(
    ({commodity}) => ({
        requestedCommodities: commodity?.requestedCommodities,
    }),
    dispatch => bindActionCreators({...commodityActions, ...userActions}, dispatch)
)(ServiceRequestForm)
