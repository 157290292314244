import React, { Component } from "react"

//component
import SelectBox from "../../formFields/SelectBox"
import Accordian from "../../Accordian"

//images
import warehouse from "../../../assets/Images/warehouse.svg"

//styles
import "./styles.scss"

export default class PaymentDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      storageDuration: [
        {
          id: 1,
          attribute: "1 Month",
          key: "1 month",
        },
        {
          id: 2,
          attribute: "2 Months",
          key: "2 months",
        },
        {
          id: 3,
          attribute: "3 Months",
          key: "3 months",
        },
        {
          id: 4,
          attribute: "4 Months",
          key: "4 months",
        },
        {
          id: 5,
          attribute: "5 Months",
          key: "5 months",
        },
        {
          id: 6,
          attribute: "6 Months",
          key: "6 months",
        },
        {
          id: 7,
          attribute: "7 Months",
          key: "7 months",
        },
        {
          id: 8,
          attribute: "8 Months",
          key: "8 months",
        },
        {
          id: 9,
          attribute: "9 Months",
          key: "9 months",
        },
        {
          id: 10,
          attribute: "10 Months",
          key: "10 months",
        },
        {
          id: 11,
          attribute: "11 Months",
          key: "11 months",
        },
        {
          id: 12,
          attribute: "12 Months",
          key: "12 months",
        },
        {
          id: 13,
          attribute: "More than 12 months",
          key: "more than 12 months",
        },
      ],
    }
    this.trackAccordionClickEvent =
      this.trackAccordionClickEvent.bind(this)
  }

  trackAccordionClickEvent() {
    if (typeof window !== "undefined" && window.gtag != null) {
      window.gtag("set", "click", { action: "warehouse_assitance" })
    }
  }

  setDuration = (value) => {
    const deliverDetails = {
      warehouseStorageRequest: {
        duration: value.key,
      },
    }
    this.props.handleChange(deliverDetails)
  }

  render() {
    return (
      <div className="warehouse-details">
        <Accordian
          src={warehouse}
          question="Looking for Warehouse Assistance?"
          questionInfo="We’ve got you covered! Store your goods at warehouses close to you."
          list={this.state.storageDuration}
          onClick={this.trackAccordionClickEvent}
          body={
            <div className="row">
              <div className="col-12 storage-duration">
                <p className="status">I want this for</p>
                <SelectBox
                  title="Select Duration"
                  list={this.state.storageDuration}
                  onSelect={this.setDuration}
                />
              </div>
            </div>
          }
        />
      </div>
    )
  }
}
