import React, {Component} from 'react';
import {Link} from "react-router-dom";

//styles
import './styles.scss';


export default class CheckboxLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: this.props.isChecked
        };
    }

    toggleCheck = () => {
        this.setState({isChecked: !this.state.isChecked})
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isChecked !== prevProps.isChecked)
            this.setState({isChecked: this.props.isChecked})
    }

    render() {
        const {name, linkText, subText, sublink, link, middleText, completeText} = this.props;
        return (
            <div className="checkbox">
                <input type="checkbox" checked={this.state.isChecked}/>
                <span></span>
                <label className="label-text">
                    {name}
                    <span className="special-text"><Link to={link}>{linkText}</Link></span>
                    {middleText}
                    <span className="special-text"><Link to={sublink}>{subText}</Link></span>
                    {completeText}
                </label>
            </div>
        )
    }
}
