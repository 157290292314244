import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Row } from "react-bootstrap";

//components
import Header from "../../components/Header";
import FilterModal from "../../components/filter/FilterModal";
import FilterPopover from "../../components/filter/FilterPopover";
import SelectBox from "../../components/formFields/SelectBox";
import InvoiceListing from "../../components/invoice/InvoiceListing";
import MobileHeader from "../../components/MobileHeader";
import BackButton from "../../components/buttons/BackButton";
import PaginationCount from "../../components/Pagination";
import Loader from "../../components/Loader";
import EmptyState from "../../components/EmptyState";

//images
import receiptIcon from "../../assets/Images/receipt.svg";
import dollarIcon from "../../assets/Images/dollar.svg";
import cart from "../../assets/Images/cart.svg";
import emptyState from "../../assets/Images/empty-state.svg";

//styles
import "./styles.scss";

//utils
import * as ledgerActions from "../../utilities/Store/actions/ledger";
import { getFormattedPrice } from "../../utilities/helpers";

const DOCUMENT_TYPES = ["Invoice", "Payment", "Credit Note"];
const MONTH_SPAN = [
  {
    id: 0,
    attribute: "Last 1 Month",
    listInfo: "",
    selected: false,
    month: 1,
  },
  {
    id: 1,
    attribute: "Last 3 months",
    listInfo: "",
    selected: false,
    month: 3,
  },
  {
    id: 2,
    attribute: "Last 6 months",
    listInfo: "",
    selected: false,
    month: 6,
  },
  {
    id: 3,
    attribute: "Last 1 Year",
    listInfo: "",
    selected: false,
    month: 12,
  },
];

class Ledger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      ledgerList: null,
      isDownloading: false,
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleInvoice = this.handleInvoice.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleInvoice(item) {
    this.setState({ isDownloading: true });
    this.props.getLedgerAttachement(item.invoice_id).then((data) => {
      window.open(data)
      this.setState({ isDownloading: false });
    });
  }

  componentDidMount() {
    document.getElementsByTagName("body")[0].className = "page-ledger";
    window.scrollTo(0, 0);
    this.fetchLedgers();
  }

  fetchLedgers = async (payload) => {
    let filters;
    if (payload && payload.filters) filters = payload.filters;
    else filters = { ...this.props.ledgerFilters };
    let res = {};
    try {
      this.setState({ loading: true });
      res = await this.props.getLedgerList(filters);
      if (res?.error === null) {
        this.setState({
          ledgerList: res.data || [],
          loading: false,
        });
      } else this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  handleFilters = (filterUpdates, fetchData) => {
    if (filterUpdates.fromMonth) {
      let currentDate = new Date();
      filterUpdates.fromDate = currentDate.setMonth(
        currentDate.getMonth() - filterUpdates.fromMonth
      );
    }
    this.props.updateLedgerFilters(filterUpdates);
    let filters = { ...this.props.ledgerFilters, ...filterUpdates };
    if (fetchData) {
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        this.fetchLedgers({ filters });
      }, 400);
    }
  };

  handlePageChange = (page) => {
    if (page) {
      let filterUpdates = {
        offset: page * 10 - 10,
      };
      this.handleFilters(filterUpdates, true);
    }
  };

  render() {
    const { show, isDownloading } = this.state;

    return (
      <div className="ledger">
        {this.state.loading && <Loader />}
        <div className="main-header-section">
          <Header />
        </div>

        <MobileHeader />

        <div className="action-btn">
          <BackButton />

          <div className="mobile-cart">
            <img className="cart" src={cart} alt="cart" />
          </div>
        </div>

        <div className="form-section">
          <Row>
            <Col xs={12} sm={12} md={8} lg={8} xl={5}>
              <div className="ledger-overview">
                <div className="amount-section">
                  <div className="summary-section">
                    <div>
                      <p className="outstanding-amount">Outstanding Amount</p>
                    </div>

                    <p className="price">
                      <span className="rupees-icon">₹</span>
                      {getFormattedPrice(this.props.ledgerList?.outstanding)}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div className="invoice-payments-section">
            <div className="main-listing">
              <h4 className="payments">Invoices & Payments</h4>

              <div className="filter-invoice">
                <SelectBox
                  title="Filter Month"
                  list={MONTH_SPAN}
                  onSelect={(selectedOption) => {
                    let filter = {
                      fromMonth: selectedOption.month,
                      fromDate: 0,
                    };
                    this.handleFilters(filter, true);
                  }}
                />

                <FilterPopover
                  onClick={this.handleShow}
                  filterKey={"documentType"}
                  activeFilters={this.props.ledgerFilters}
                  filterOne="Settled"
                  filtertwo="Invoice Status"
                  filterthree="Payment"
                  filterfour="Invoice Type"
                />
              </div>
            </div>

            <div className="invoice-payments-table">
              <div className="heading-section">
                <p className="main-heading invoice-serial-no"></p>
                <p className="main-heading invoice-Date">DATE</p>
                <p className="main-heading invoice-amount">AMOUNT</p>
                <p className="main-heading invoice-status">STATUS</p>
                {/* <p className="main-heading invoice-mode">MODE</p> */}
                <p className="main-heading invoice-paynow"></p>
              </div>

              {this.props.ledgerList?.document_items?.length > 0 ? (
                this.props.ledgerList?.document_items?.map((item) => {
                  return (
                    <InvoiceListing
                      handleClick={() => this.handleInvoice(item)}
                      iconImg={
                        item.document_type === "Invoice"
                          ? receiptIcon
                          : dollarIcon
                      }
                      iconName={item.document_type}
                      {...item}
                      isDownloading={isDownloading}
                    />
                  );
                })
              ) : (
                <EmptyState
                  src={emptyState}
                  message="No ledgers found"
                  info=""
                />
              )}
            </div>
          </div>
          <PaginationCount
            dataCount={this.props.ledgerList?.document_items?.length}
            handlePageChange={this.handlePageChange}
          />
        </div>

        <FilterModal
          show={show}
          filterKey={"documentType"}
          onHide={this.handleClose}
          title="Filter Invoice and Payments"
          categoryHeadings={["Document Type"]}
          firstFilterOptions={DOCUMENT_TYPES}
          handleFilters={this.handleFilters}
        />
      </div>
    );
  }
}

export default connect(
  ({ ledgers }) => ({
    ...ledgers,
    ledgerList: ledgers.list,
    ledgerFilters: ledgers.filters,
  }),
  (dispatch) => bindActionCreators({ ...ledgerActions }, dispatch)
)(Ledger);
