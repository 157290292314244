import { get, URL } from "../../network";
import * as Actions from "./actionTypes";
import { createQueryParamUrl } from "../../helpers";
import LocalStorage from "../../localStorage";
import { clearUserDetails } from "./user";
import { clearCommodities } from "./commodity";
import { USER_DISABLE_ERROR } from "../../common/constants";
import keycloak from "../../keycloak/config";

const handleError = (err) => {
  return Promise.reject(err.response || err);
};

export const getInsightsList = (filters) => async (dispatch) => {
  try {
    let url = generateFilterUrl(URL.insights.list, filters);
    console.log(url);
    const response = await get(url)
    if (response?.error === null && response?.data) {
      dispatch({
        type: Actions.SET_INSIGHTS_LIST,
        data: response.data,
      });
      dispatch({
        type: Actions.SET_INSIGHTS_COUNT,
        data: response.count,
      });
      return response;
    } else if (response?.error === USER_DISABLE_ERROR) handleLogout();
    return null;
  } catch (err) {
    return handleError(err);
  }
};

export const updateFilters = (filterUpdates) => (dispatch) => {
  dispatch({
    type: Actions.SET_INSIGHTS_FILTERS,
    data: filterUpdates,
  });
};

const generateFilterUrl = (sourceUrl, filters) => {
  let mappings = {},
    params = [],
    url = "";
  Object.keys(filters).forEach((key) => {
    // eslint-disable-next-line eqeqeq
    if (filters[key] != undefined) mappings[key] = filters[key];
  });
  params = createQueryParamUrl(mappings).join("&");
  url = `${sourceUrl}?${params}`;
  return url;
};

const handleLogout = () => {
  keycloak.logout();
  LocalStorage.destroy.all();
  clearCommodities();
  clearUserDetails();
  window.location.href = "/";
};
