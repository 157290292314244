import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"

//components
import OutlineButton from "../../buttons/OutlineButton"

//icons
import { GoTriangleLeft } from "react-icons/go"

//images
import orange from "../../../assets/Images/orange.png"

//styles
import "./styles.scss"

//utils
import moment from "moment"
import { getFormattedPrice } from "../../../utilities/helpers"
import LocalStorage from "../../../utilities/localStorage"

class OrderItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listOpen: false,
      popoverOpen: false,
      details: false,
    }
    this.togglePopover = this.togglePopover.bind(this)
    this.togglePopoverHide = this.togglePopoverHide.bind(this)
    this.listBox = this.listBox.bind(this)
  }

  trackToggleOrderDetailEvent() {
    if (typeof window !== "undefined" && window.gtag != null) {
      window.gtag("event", "click", { action: "order_item_row_toggle" })
    }
  }

  trackViewDetailEvent() {
    if (typeof window !== "undefined" && window.gtag != null) {
      window.gtag("event", "click", { action: "order_detail_view" })
    }
  }

  OrderlistOpen() {
    this.trackToggleOrderDetailEvent()
    this.setState((prevState) => ({
      listOpen: !prevState.listOpen,
    }))
  }

  listBox() {
    this.setState((prevState) => ({
      details: !prevState.details,
    }))
  }

  togglePopover() {
    this.setState({ popoverOpen: true })
  }

  togglePopoverHide() {
    this.setState({ popoverOpen: false })
  }

  getStatusColorCodes = (status) => {
    switch (status?.toLowerCase()) {
      case "in review":
        return "blue"
      case "sale":
        return "green"
      case "cancel":
        return "red"
      case "ongoing":
        return "yellow"
      default:
        return "green"
    }
  }

  render() {
    const { listOpen, popoverOpen } = this.state
    const {
      orderDetail: {
        name: productCode,
        date,
        id,
        untaxed_amount,
        tax_amount,
        status,
        order_total,
        order_lines: subOrders,
      },
    } = this.props

    return (
      <div
        key={id}
        className="order-listings-table"
        style={listOpen ? { borderBottom: "0.6rem solid #e0e3e8" } : null}
      >
        <Link to="#" 
          onClick={() => this.OrderlistOpen()}
          className="order-list-name"
          style={listOpen ? { backgroundColor: "#f5f7fd" } : null}
        >
          <div className="order-image listings">
            <img className="order-img" src={subOrders?.[0]?.product_image_url} alt="order-list" />
          </div>
          <div className="order-code listings">
            <p className="qr-code">{productCode}</p>
            <p className="date">{moment(date * 1000).format("DD/MM/YYYY")}</p>
          </div>
          <div className="order-commodity listings">
            <p className="commodity-name">
              {subOrders?.[0]?.template_name}
              <span
                onMouseOver={this.togglePopover}
                onMouseLeave={this.togglePopoverHide}
              >
                {/*{plus}*/}
              </span>
            </p>
            <p className="commodity-info">{subOrders?.[0]?.product_name}</p>
            {popoverOpen ? (
              <div className="popover-items">
                <GoTriangleLeft className="dropleft" />
                <div>
                  <img className="order-img" src={orange} alt="order-list" />
                </div>
                <div className="product-list">
                  <div className="popover-products">
                    <div>
                      <p className="commodity-name">Oranges</p>
                      <p className="commodity-info">Nagpuri</p>
                    </div>
                    <div>
                      <p className="commodity-name right">150 kg</p>
                      <p className="commodity-info right">Quantity</p>
                    </div>
                  </div>
                  <div className="popover-products">
                    <div>
                      <p className="commodity-name">₹34 per kg</p>
                      <p className="commodity-info">Cost estimated at</p>
                    </div>
                    <div>
                      <p className="commodity-name right">₹5,100.00</p>
                      <p className="commodity-info right">Total</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="order-amount listings">
            <p className="amount">
              <span className="rupees-icon">₹</span>
              {getFormattedPrice(order_total)}
            </p>
          </div>
          <div className="order-status listings">
            <button className={`outlines ${this.getStatusColorCodes(status)}`}>
              {status}
            </button>
          </div>
          <div
            className="order-details-btn listings"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              LocalStorage.set.orderDetails(
                JSON.stringify(this.props.orderDetail)
              )
              this.trackViewDetailEvent()
              this.props.history.push("/order-details")
            }}
          >
            <OutlineButton btnText="View Details" />
          </div>
        </Link>
        {listOpen ? (
          <div className="order-list-grand-total">
            <div className="heading-section">
              <p className="main-heading order-image"></p>
              <p className="main-heading order-commodity">Commodity</p>
              <p className="main-heading order-quantity">Quantity</p>
              <p className="main-heading order-estimate">Estimate</p>
              <p className="main-heading order-price">Price</p>
            </div>
            {subOrders.map((item) => (
              <div key={item.product_name} className="item-desc">
                <div className="stock-img">
                  <img className="order-img" src={item.product_image_url} alt="order-list" />
                </div>
                <div className="container-box items">
                  <p className="item-name">{item.template_name}</p>
                  <p className="item-info">{item.product_name}</p>
                </div>
                <div className="container-box">
                  <p className="item-name">
                    {item.product_qty} {item.product_unit}
                  </p>
                  <p className="item-info">Quantity</p>
                </div>
                <div className="container-box">
                  <p className="item-name">
                    ₹{getFormattedPrice(item.price_unit)} per{" "}
                    {item.product_unit}
                  </p>
                  <p className="item-info">Cost estimated at</p>
                </div>
                <div className="amount">
                  <p className="item-name">
                    <span className="rupees-icon">₹</span>
                    {getFormattedPrice(
                      (Math.round(item.total_price * 100) / 100).toFixed(2)
                    )}
                  </p>
                </div>
              </div>
            ))}
            <div className="total-storage">
              <div className="status">
                <p className="item-info">Taxed amount</p>
                <p className="item-name">
                  <span className="rupees-icon">₹</span>
                  {getFormattedPrice(tax_amount)}
                </p>
              </div>
              <div className="status">
                <p className="item-info">Untaxed amount</p>
                <p className="item-name">
                  <span className="rupees-icon">₹</span>
                  {getFormattedPrice(untaxed_amount)}
                </p>
              </div>
            </div>
            <div className="total-estimated">
              <div className="container-box">
                <p className="item-names">Total estimated</p>
              </div>
              <div className="container-box">
                <p className="item-name">
                  <span className="rupees-icon">₹</span>
                  {getFormattedPrice(order_total)}
                </p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

export default withRouter(OrderItem)
