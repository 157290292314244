import React, { Component } from "react";
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

//components
import PrimaryButton from '../buttons/PrimaryButton';
import OutlineButton from "../buttons/OutlineButton";

//images
import blackCancelIcon from '../../assets/Images/black-cancel-icon.svg';

//styles
import './styles.scss';


export default class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { info, show, handleClick, onHide, handleClickCancel, infoActive, src, message, OutlineButtonActive, headerActive, primaryBtnText } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-section confirmation-modal"
      >
        {headerActive && (
          <Modal.Header>
            <Link to="#" className="cancel-button" onClick={onHide}>
              <img className="black-icon" src={blackCancelIcon} alt="cancel-icon" />
            </Link>
          </Modal.Header>
        )}

        <Modal.Body>
          <div>
            <img className="delete-icon" src={src} alt="remove-icon" />
          </div>

          <p className="message">{message}</p>

          {infoActive && (
            <p className="info">{info}</p>
          )}

          <div className="action-btns">
            <PrimaryButton
              handleClick={handleClick}
              btnText={primaryBtnText}
            />

            {OutlineButtonActive && (
              <OutlineButton
                handleClick={handleClickCancel}
                btnText="Cancel"
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
