import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

//component
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import EmptyState from "../../EmptyState"
import ConfirmationModal from "../../ConfirmationModal"

//images
import emptyState from "../../../assets/Images/empty-state.svg"
import cancelIcon from "../../../assets/Images/cancel-gray-icon.svg"
import submitImg from "../../../assets/Images/submit.svg"
import exclamationIcon from "../../../assets/Images/exclamation.svg"

//actions
import * as commodityActions from "../../../utilities/Store/actions/commodity"
import * as orderActions from "../../../utilities/Store/actions/orders"

//styles
import "./styles.scss"

// utils
import { round } from "../../../utilities/helpers"
class RequestSummary extends Component {
  state = {
    confirmationBoxActive: false,
    summarylist: true,
  }

  confirmationBoxShow = (e, isError) => {
    let message = isError
        ? "Order request failed"
        : "Order submitted successfully!",
      info = isError
        ? "Something went wrong"
        : "We have received your request and will update you soon.",
      ctaText = isError ? "Go back" : ""
    if (e?.status === 409) {
      message = e?.data?.error?.message
    } else if (e?.status === 400) {
      message = "Order request failed"
      info = e?.data?.error
    }

    this.setState({
      confirmationBoxActive: true,
      isError,
      confirmationBoxMessage: {
        info,
        message,
        ctaText,
      },
    })
  }

  confirmationBoxHide = () => {
    this.setState({
      confirmationBoxActive: false,
    })
  }

  getSubtotal = (orderSummary) => {
    return orderSummary
      .map((item) => +item.cost || 0)
      .reduce((a, b) => a + b, 0)
  }

  getTotalTax = (orderSummary) => {
    return orderSummary
      .map((item) => +(item.tax > 0 && (item.cost * item.tax) / 100) || 0)
      .reduce((a, b) => a + b, 0)
  }

  isOrderSummaryValid = (orders = []) => {
    let obj = {
      quantity: [],
      variant: [],
      price: [],
    }
    orders.map((item) => {
      if (!item.quantity) {
        obj.quantity.push(item.id)
      }
      if (!item.cost) {
        obj.price.push(item.id)
      }
      return 0
    })
    return obj
  }

  trackSubmitOrderEvent() {
    if (typeof window !== "undefined" && window.gtag != null) {
      window.gtag("event", "click", { action: "submit_order" })
    }
  }

  submitOrder = async () => {
    let formErrors = this.isOrderSummaryValid(this.props.requestedCommodities)
    if (
      formErrors.price.length === 0 &&
      formErrors.quantity.length === 0 &&
      this.props.partnerType
    ) {
      this.setState({ loading: true })
      let data = {
        partner_type: this.props?.partnerType,
        order_line: this.props?.requestedCommodities?.map((item) => {
          let selectedVariant = item.variants.find(
            (ele) => ele.attribute == null
          )
          if (!selectedVariant) {
            selectedVariant = item.variants[0]
          }
          const order_line_details = {
            product_id: selectedVariant?.id,
            price_unit: +(+item?.cost / +item?.quantity).toFixed(2),
            product_uom_qty: item?.quantity,
          }
          if (item?.quality) {
            order_line_details["quality_request"] = item?.quality
          }
          return order_line_details
        }),
        delivery_type: {
          type: this.props.deliveryDetails?.deliveryType || "onetime",
        },
        create_time_st: (new Date().getTime() / 1000).toFixed(),
        scheduled_date: (new Date().getTime() / 1000).toFixed(),
      }
      if (this.props.deliveryDetails?.orderPickUpType === "deliverytoyou") {
        data.address_id = this.props.deliveryDetails?.address?.id
      }
      if (data.delivery_type.type === "installments") {
        data.delivery_type.delivery_end_date =
          this.props.deliveryDetails?.deliveryEndDate
      }
      if (this.props.deliveryDetails?.financialAssistance) {
        data.financial_assistance_request =
          this.props.deliveryDetails?.financialAssistance
      }
      if (this.props.deliveryDetails?.warehouseStorageRequest) {
        data.warehouse_storage_request =
          this.props.deliveryDetails?.warehouseStorageRequest
      }
      if (this.props.deliveryDetails?.specialRequest) {
        data.special_request = this.props.deliveryDetails?.specialRequest
      }
      try {
        let res = await this.props.createOrder(data)
        if (res?.error === null) {
          this.confirmationBoxShow()
          this.setState({ loading: false })
        } else {
          this.confirmationBoxShow(res, true)
        }
      } catch (error) {
        this.setState({ loading: false })
        this.confirmationBoxShow(error, true)
      }
    } else {
      let obj = {
        errors: {},
      }
      if (!this.props.partnerType) obj.errors.partnerType = "Please select one"
      if (formErrors.price.length > 0 || formErrors.quantity.length > 0)
        obj.errors.orderErrors = formErrors
      this.props.updateErrors(obj)
    }
  }

  render() {
    const { confirmationBoxActive, confirmationBoxMessage } = this.state
    const { onClick, requestedCommodities = [] } = this.props
    return (
      <div className="request-summary">
        <div className="main-heading">
          <h6 className="sub-heading">Request Summary</h6>
          <Link to="#" onClick={onClick} className="gray-cancel-item">
            <img src={cancelIcon} alt="cancel-icon" />
          </Link>
        </div>

        {requestedCommodities?.length > 0 ? (
          <div className="summary-section">
            <div className="sub-total">
              <div className="payment-section">
                {requestedCommodities?.map((item) => {
                  return (
                    <div className="description" key={item.name}>
                      <p className="left-item">{item.name}</p>
                      <p className="right-item">₹{item.cost || 0}/-</p>
                    </div>
                  )
                })}
              </div>

              <div className="delivery-section">
                <div className="description">
                  <p className="left-item">
                    Item subtotal ({requestedCommodities?.length} Items)
                  </p>
                  <p className="right-item">
                    ₹{this.getSubtotal(requestedCommodities)}/-
                  </p>
                </div>

                <div className="description">
                  <p className="left-item">Taxes</p>
                  <p className="right-item">
                    ₹{this.getTotalTax(requestedCommodities)}/-
                  </p>
                </div>
              </div>
            </div>

            <div className="grand-total">
              <div className="description">
                <p className="left-item">Total payable (estimate)</p>
                <p className="right-item">
                  ₹
                  {round(
                    this.getSubtotal(requestedCommodities) +
                      this.getTotalTax(requestedCommodities),
                    2
                  )}
                  /-
                </p>
              </div>

              <PrimaryButton
                btnText="Submit Request"
                handleClick={() => {
                  this.trackSubmitOrderEvent()
                  this.submitOrder()
                }}
              />
            </div>
          </div>
        ) : (
          <EmptyState
            src={emptyState}
            message="Summary of your order specifications and total cost will come here."
            info="Continue to fill the form."
          />
        )}

        {confirmationBoxActive ? (
          <ConfirmationModal
            src={this.state.isError ? exclamationIcon : submitImg}
            show={this.confirmationBoxShow}
            onHide={this.confirmationBoxHide}
            message={
              confirmationBoxMessage.message || "Order submitted successfully!"
            }
            info={
              confirmationBoxMessage.info ||
              "We have received your request and will update you soon."
            }
            primaryBtnText={confirmationBoxMessage.ctaText || "Go to home"}
            handleClick={() => {
              if (this.state.isError) return this.confirmationBoxHide()
              return this.props.history.push("/home-page")
            }}
            infoActive={true}
            handleClickCancel={this.confirmationBoxHide}
          />
        ) : null}
      </div>
    )
  }
}

export default withRouter(
  connect(
    ({ commodity }) => ({
      requestedCommodities: commodity?.requestedCommodities,
    }),
    (dispatch) =>
      bindActionCreators({ ...commodityActions, ...orderActions }, dispatch)
  )(RequestSummary)
)
