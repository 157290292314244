import React, { Component } from "react"
import { Route, Switch } from "react-router-dom"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"

//utilities
import * as userActions from "./utilities/Store/actions/user"
import LocalStorage from "./utilities/localStorage"

//containers
import CommodityCatelogue from "./containers/CommodityCatelogue"
import Home from "./containers/HomePage"
import CommodityDetails from "./containers/CommodityDetails"
import ServiceRequestForm from "./containers/ServiceRequestForm"
import OrderListing from "./containers/OrderListing"
import OrderDetails from "./containers/OrderDetails"
import Ledger from "./containers/Ledger"
import Help from "./containers/Help"
import NoInternetConnection from "./containers/NoInternetConnection"
import Insights from "./containers/Insights"

class DashboardContainer extends Component {
  componentDidMount() {
    this.handleUserInfo()
  }

  handleUserInfo = async () => {
    let userDetails = await this.props.getUserInfo()
    if (userDetails?.data?.company?.name !== "") {
      const { history } = this.props
      if (!userDetails?.data?.company?.name) {
        LocalStorage.destroy.all()
        history.push("/no-internet")
      } else {
        history.push("/home-page")
      }
    }
  }

  render() {
    return (
      <div className="main-container">
        <div className="mainContent--sec">
          <Switch>
            <Route
              exact
              path="/commodity-catelogue"
              component={CommodityCatelogue}
            />
            <Route exact path="/home-page" component={Home} />
            <Route
              exact
              path="/commodity-details"
              component={CommodityDetails}
            />
            <Route
              exact
              path="/service-request-form"
              component={ServiceRequestForm}
            />
            <Route exact path="/order-listing" component={OrderListing} />
            <Route exact path="/order-details" component={OrderDetails} />
            <Route exact path="/ledger" component={Ledger} />
            <Route exact path="/help" component={Help} />
            <Route exact path="/no-internet" component={NoInternetConnection} />
            <Route exact path="/insights" component={Insights} />
          </Switch>
        </div>
      </div>
    )
  }
}

const connectedComponent = connect(
  () => ({}),
  (dispatch) => bindActionCreators({ ...userActions }, dispatch)
)(DashboardContainer)

export default withRouter(connectedComponent)
