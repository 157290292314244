import * as Actions from '../actions/actionTypes'

let initialState = {
    filters: {
        limit: 10,
        offset: 0
    },
    list: []
};

export const ledgers = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SET_LEDGER_LIST:
            return {
                ...state,
                list: action.data
            }
        case Actions.SET_LEDGER_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.data
                }

            }
        default:
            return state;
    }
};
