const URL = {
  login: {
    requestOtp: "/api/v1/pub/otp",
    verifyOtp: "/api/v1/pub/token",
    userInfo: "/v1/me",
  },
  commodity: {
    list: "/v1/products",
  },
  orders: {
    list: "/v1/orders",
    create: "/v1/order",
  },
  insights: {
    list: "/v1/marketplace/reports",
    filters: "/v1/marketplace/reports-metadata",
    download: "/v1/marketplace/download-reports?file_name="
  },
  ledgers: {
    list: "/v1/ledger",
    attachement: "/v1/ledger-entry-attachment",
  },
  address: {
    list: "/v1/addresses",
  },
};

export { URL };
