const LS_KEY = {
  AUTH_TOKEN: "auth_token",
  USER_DETAIL: "user-detail",
  PERSIST_ROOT: "persist:dehaat-root",
  SELECTED_COMMODITIES: "selected-commodities",
  AEROS_AUTH_TOKEN: "aeros_auth_token",
  ORDER_DETAILS: "order_details",
}

const set = {
  authToken: (data) => {
    localStorage.setItem(LS_KEY.AUTH_TOKEN, data)
  },
  userDetail: (data) => {
    localStorage.setItem(LS_KEY.USER_DETAIL, data)
  },
  selectedCommodities: (data) => {
    localStorage.setItem(LS_KEY.SELECTED_COMMODITIES, data)
  },
  aerosAuthToken: (data) => {
    localStorage.setItem(LS_KEY.AEROS_AUTH_TOKEN, data)
  },
  orderDetails: (data) => {
    localStorage.setItem(LS_KEY.ORDER_DETAILS, data)
  },
}

const fetch = {
  authToken: () => {
    const data = localStorage.getItem(LS_KEY.AUTH_TOKEN)
    if (data) {
      try {
        return data
      } catch (err) {
        console.log(err)
      }
    }
  },
  selectedCommodities: () => {
    const data = localStorage.getItem(LS_KEY.SELECTED_COMMODITIES)
    if (data) {
      try {
        return data
      } catch (err) {
        console.log(err)
      }
    }
  },
  aerosAuthToken: () => {
    const data = localStorage.getItem(LS_KEY.AEROS_AUTH_TOKEN)
    if (data) {
      try {
        return data
      } catch (err) {
        console.log(err)
      }
    }
  },
  orderDetails: () => {
    const data = localStorage.getItem(LS_KEY.ORDER_DETAILS)
    if (data) {
      try {
        return data
      } catch (err) {
        console.log(err)
      }
    }
  },
  root: () => {
    const data = localStorage.getItem(LS_KEY.PERSIST_ROOT)
    if (data) {
      try {
        return data
      } catch (err) {
        console.log(err)
      }
    }
  }
}

const destroy = {
  authToken: () => {
    localStorage.removeItem(LS_KEY.AUTH_TOKEN)
  },
  selectedCommodities: () => {
    localStorage.removeItem(LS_KEY.SELECTED_COMMODITIES)
  },
  all: () => {
    localStorage.removeItem(LS_KEY.AUTH_TOKEN)
    localStorage.removeItem(LS_KEY.USER_DETAIL)
    localStorage.removeItem(LS_KEY.PERSIST_ROOT)
    localStorage.removeItem(LS_KEY.SELECTED_COMMODITIES)
    localStorage.removeItem(LS_KEY.AEROS_AUTH_TOKEN)
    localStorage.removeItem(LS_KEY.ORDER_DETAILS)
  },
}

export default {
  set,
  fetch,
  destroy,
}
