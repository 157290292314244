import {applyMiddleware, combineReducers, compose, createStore} from "redux"
import thunkMiddleware from "redux-thunk"
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {commodity} from './reducers/commodity'
import {orders} from "./reducers/orders"
import {ledgers} from "./reducers/ledger"
import {login} from './reducers/login'
import {user} from './reducers/user'
import {insights} from './reducers/insights'

const rootPersistConfig = {
    key: 'dehaat-root',
    storage,
    whitelist: ['login', 'user']
}

const rootReducer = combineReducers({
    user,
    commodity,
    orders,
    ledgers,
    login,
    insights
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

const devTool =
    process.env.NODE_ENV === "development"
        ? window.__REDUX_DEVTOOLS_EXTENSION__ ?
        window.__REDUX_DEVTOOLS_EXTENSION__()
        : compose
        : compose;

const store = createStore(
    persistedReducer,
    compose(
        applyMiddleware(thunkMiddleware),
        devTool
    )
);

export default store;
