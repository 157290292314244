import React, {Component} from 'react';
import {Link} from "react-router-dom";

//icons
import {MdArrowDropUp} from "react-icons/md";

//images
import filterIcon from '../../../assets/Images/filter-icon.svg';

//styles
import './styles.scss';


export default class FilterPopover extends Component {
    constructor() {
        super();
        this.state = {
            popoverOpen: false,
        };

        this.togglePopover = this.togglePopover.bind(this);
        this.togglePopoverHide = this.togglePopoverHide.bind(this);
    }

    togglePopover() {
        this.setState({popoverOpen: true})
    }

    togglePopoverHide() {
        this.setState({popoverOpen: false})
    }

    render() {
        const {popoverOpen} = this.state;
        const {onClick, filtertwo, activeFilters} = this.props;

        return (
            <div className="filter-popover">
                <Link to="#" 
                    onClick={onClick}
                    className="icon"
                    onMouseOver={this.togglePopover}
                    onMouseLeave={this.togglePopoverHide}
                >
                    {activeFilters?.[this.props.filterKey] ? (<div className="red-dot"></div>) : (null)}
                    <img className="icon-img" src={filterIcon} alt="filter"/>
                </Link>

                {popoverOpen ? (
                    <div className="popover">
                        <MdArrowDropUp className="dropup"/>
                        <h5 className="filter-heading">Filters Applied</h5>
                        <div className="filter-info mb-2">
                            <p className="filter-name">{activeFilters?.[this.props.filterKey]}</p>
                            <p className="filter-type">{filtertwo}</p>
                        </div>
                    </div>
                ) : (null)}
            </div>
        )
    }
}
