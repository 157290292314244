export const createQueryParamUrl = (filterKeys) => {
  let params = [];
  try {
    Object.keys(filterKeys).map((key) => {
      if (filterKeys[key] !== null) {
        params.push(`${key}=${filterKeys[key]}`);
      }
      return 0;
    });
  } catch (e) {}
  return params;
};

export const getFormattedPrice = (value) => {
  return value || value === 0 ? value?.toLocaleString("en-IN") : "";
};

export const getSelectBoxList = (obj) => {
  return obj.map(
    (item) => ({
      id: item.id,
      attribute: `${item.name} ${item.street} ${item.street2} ${item.city} ${item.zip}`,
    }),
    []
  );
};

export const changeDateFormat = (date) => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  return yyyy + "-" + mm + "-" + dd;
};

export const round = (number, decimalPlaces) => {
  const factorOfTen = Math.pow(10, decimalPlaces);
  return Math.round(number * factorOfTen) / factorOfTen;
};
