import React , {Component} from 'react';
import { Tabs, Tab } from 'react-bootstrap'
import { Link } from 'react-router-dom'

//components
import Header from '../../components/Header';
import CommodityTabsContent from '../../components/CommodityTabsContent';
import MobileHeader from '../../components/MobileHeader';

//icon
import { FaAngleLeft } from "react-icons/fa";

//styles
import './styles.scss';


export default class CommodityDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].className = 'page-home';
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="commodity-details">
        <div className="main-header-section">
          <Header />
        </div>

        <MobileHeader />

        <div className="tabs-section">
          <div className="tabs-bar">
            <div className="item-name">
              <p className="selected-item-name">
                <Link to="/home-page" className="icon-left"><FaAngleLeft /></Link>
                  Mango
              </p> 
            </div>
            <Tabs defaultActiveKey="alphonso">
              <Tab eventKey="alphonso" title="Alphonso">
                <CommodityTabsContent />
              </Tab>

              <Tab eventKey="dasehri" title="Dasehri">
                <CommodityTabsContent />
              </Tab>

              <Tab eventKey="himsagar" title="Himsagar">
                <CommodityTabsContent />
              </Tab>

              <Tab eventKey="chaunsa" title="Chaunsa">
                <CommodityTabsContent />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    )
  }
}
