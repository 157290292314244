import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

//components
import Header from '../../components/Header';
import OrderItem from '../../components/orders/OrderItem';
import FilterModal from '../../components/filter/FilterModal';
import FilterPopover from '../../components/filter/FilterPopover';
import PaginationCount from '../../components/Pagination';
import MobileHeader from '../../components/MobileHeader';
import FlagModal from '../../components/flag/FlagModal';
import ConfirmationModal from '../../components/ConfirmationModal';
import Loader from "../../components/Loader";
import EmptyState from "../../components/EmptyState";

//actions
import * as orderActions from "../../utilities/Store/actions/orders";

//images
import successIcon from '../../assets/Images/success-icon.svg';
import emptyState from "../../assets/Images/empty-state.svg";

//styles
import './styles.scss'

const ORDER_STATUS = ['Completed', 'Ongoing', 'In Review', 'Cancel']

class OrderListing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            flagshow: false,
            successShow: false,
            liveUpdates: false,
            selected: "",
            orderList: [],
            dataCount: null
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleFlagShow = this.handleFlagShow.bind(this);
        this.handleFlagClose = this.handleFlagClose.bind(this);
        this.successModal = this.successModal.bind(this);
    }

    handleFlagClose() {
        this.setState({show: false, flagshow: false, successShow: false});
    }

    handleClose() {
        this.setState({show: false});
    }

    handleFlagShow() {
        this.setState({flagshow: true});
    }

    handleShow() {
        this.setState({show: true});
    }

    successModal() {
        this.setState({flagshow: false, successShow: true});
    }

    setSelected = (value) => {
        this.setState({selected: value})
    }

    handleClick = (event) => {
        this.setState({shows: true, target: event})
    }

    togglePopover() {
        this.setState({popoverOpen: !this.state.popoverOpen})
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].className = 'page-order-listing';
        window.scrollTo(0, 0)
        this.fetchOrders()
    }

    fetchOrders = async (payload) => {
        let filters;
        if (payload && payload.filters)
            filters = payload.filters
        else
            filters = {...this.props.orderFilters};
        let res = {}
        try {
            this.setState({loading: true})
            res = await this.props.getOrdersList(filters)
            if (res?.error === null) {
                this.setState({
                    orderList: res.data?.orders || [],
                    loading: false,
                    dataCount: res.data?.count || null
                })
            } else
                this.setState({loading: false})
        } catch (e) {
            this.setState({loading: false})
        }
    }

    handlePageChange = (page) => {
        if (page) {
            let filterUpdates = {
                offset: (page * 10) - 10
            }
            this.handleFilters(filterUpdates, true)
        }
    }

    handleFilters = (filterUpdates, fetchData) => {
        this.props.updateOrderFilters(filterUpdates)
        let filters = {...this.props.orderFilters, ...filterUpdates}
        if (fetchData) {
            clearTimeout(this.timer)
            this.timer = setTimeout(async () => {
                this.fetchOrders({filters})
            }, 400)
        }
    }

    componentWillUnmount() {
        this.props.updateOrderFilters({status: ''})
    }

    render() {
        return (
            <div className="order-listing">
                {this.state.loading && <Loader/>}
                <div className="main-header-section">
                    <Header/>
                </div>
                <MobileHeader/>
                <div className="info-section">
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="mobile-heading-updates">
                                <h1 className="orders">Order History</h1>
                            </div>
                            <div className="order-section">
                                <div className="main-listing">
                                    <h4 className="orders">Order History</h4>
                                    <div className="filter-section">
                                        <div className="filter-orders">
                                            <FilterPopover
                                                filterKey={'status'}
                                                onClick={this.handleShow}
                                                filtertwo="Order Status"
                                                activeFilters={this.props.orderFilters}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="table-section">
                                    <div className="heading-section">
                                        <p className="main-heading order-image"/>
                                        <p className="main-heading order-code">Code</p>
                                        <p className="main-heading order-commodity">Commodity</p>
                                        <p className="main-heading order-amount">amount</p>
                                        <p className="main-heading order-status">Status</p>
                                        <p className="main-heading order-flag"></p>
                                    </div>
                                    {
                                        this.state.orderList?.length > 0 ?
                                            this.state.orderList?.map(item => <OrderItem
                                                orderDetail={item}
                                            />)
                                            :
                                            <EmptyState
                                                src={emptyState}
                                                message="No orders found"
                                                info=""
                                            />
                                    }
                                </div>
                            </div>
                            <PaginationCount dataCount={this.state.dataCount} handlePageChange={this.handlePageChange}/>
                        </Col>
                    </Row>
                </div>
                <FilterModal
                    show={this.state.show}
                    filterKey={'status'}
                    onHide={this.handleClose}
                    title="Filter Orders"
                    categoryHeadings={['Status']}
                    firstFilterOptions={ORDER_STATUS}
                    handleFilters={this.handleFilters}
                />
                <FlagModal
                    show={this.state.flagshow}
                    onHide={this.handleFlagClose}
                    handleClick={this.successModal}
                    btnText="Ongoing"
                    status="70% complete"
                />
                <ConfirmationModal
                    show={this.state.successShow}
                    src={successIcon}
                    message="Issue flagged successfully!"
                    info="We have received your concern and will update you soon. You can see the status of your Flag under the Help section."
                    infoActive={true}
                    primaryBtnText="OK"
                    handleClick={this.handleFlagClose}
                />
            </div>
        )
    }
}

export default connect(
    ({orders}) => ({
        ...orders,
        orderFilters: orders.filters
    }),
    dispatch => bindActionCreators({...orderActions}, dispatch)
)(OrderListing)
