import React, { Component } from "react"
import { Col, Row } from "react-bootstrap"
import { withRouter } from "react-router-dom"

//components
import Header from "../../components/Header"
import BackButton from "../../components/buttons/BackButton"
import OrderTimeline from "../../components/orders/OrderTimeline"
import EmptyState from "../../components/EmptyState"
import RecurringOrders from "../../components/orders/RecurringOrders"

//images
import commodityDetail from "../../assets/Images/commodity-detail.svg"
import logo from "../../assets/Images/bitmap.png"
import cart from "../../assets/Images/cart.svg"
import logisticIcon from "../../assets/Images/logistics.svg"
import financialIcon from "../../assets/Images/financial.svg"
import specialIcon from "../../assets/Images/special-request.svg"
import deliveryIcon from "../../assets/Images/delivery.svg"
import emptyState from "../../assets/Images/empty-state.svg"

//styles
import "./styles.scss"

//utils
import { getFormattedPrice } from "../../utilities/helpers"
import moment from "moment"
import LocalStorage from "../../utilities/localStorage"

class OrderDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      timeline: false,
    }
    this.handleShowTimeline = this.handleShowTimeline.bind(this)
    this.handleHideTimeline = this.handleHideTimeline.bind(this)
  }

  handleShowTimeline() {
    this.setState({ timeline: true })
  }

  handleHideTimeline() {
    this.setState({ timeline: false })
  }

  componentDidMount() {
    document.getElementsByTagName("body")[0].className =
      "page-order-listing-details"
    window.scrollTo(0, 0)
    if (this.props?.location?.state?.orderDetail) {
      this.setState({ orderDetail: this.props?.location?.state?.orderDetail })
    }
  }

  render() {
    const { timeline } = this.state
    const orderDetail = JSON.parse(LocalStorage.fetch.orderDetails())

    return (
      <div className="order-listing-details">
        <div className="main-header-section">
          <Header />
        </div>
        <div className="form-section">
          <div className="action-btn">
            <BackButton />
            <div className="mobile-cart">
              <img className="cart" src={cart} alt="cart" />
              <img src={logo} alt="logo" />
            </div>
          </div>
          {timeline ? (
            <div className="mobile-timeline">
              <div className="backdrops"></div>
              <OrderTimeline
                details={orderDetail?.sales_person}
                onClick={this.handleHideTimeline}
              />
            </div>
          ) : null}
          <h4 className="heading">Order Details</h4>
          <p className="order-number">
            Ordered on {moment(orderDetail?.date * 1000).format("DD MMM YYYY")}
            <span className="space">|</span>
            Order #{orderDetail?.name}
          </p>
          {orderDetail?.order_lines.length > 0 && (
            <Row>
              <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                <div className="commodity-detail-section">
                  <div className="icon-with-detail">
                    <img
                      src={commodityDetail}
                      alt="commodity-detail-icon"
                      width="30px"
                      height="30px"
                    />
                    <span className="commodity-detail">Commodity Details</span>
                  </div>
                  {orderDetail?.order_lines?.map((item) => {
                    return (
                      <div className="info-section">
                        <div className="detail">
                          <img className="item-image" src={item.product_image_url}   alt="item" />
                          <div className="content">
                            <p className="name">{item.product_name}</p>
                          </div>
                        </div>
                        <div className="list">
                          <div className="descriptions">
                            <div className="info-flow">
                              <p className="sub-heading">Variety</p>
                              <p className="info">{item.template_name}</p>
                            </div>
                            <div className="info-flow">
                              <p className="sub-heading">Quantity</p>
                              <p className="info">
                                {item.product_qty} {item.product_unit}
                              </p>
                            </div>
                          </div>
                          <div className="field-section">
                            <div className="region">
                              <p className="sub-heading">Price</p>
                              <p className="info">
                                ₹{getFormattedPrice(item.total_price)}/- @ ₹
                                {item.price_unit}/{item.product_unit}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </Col>
              {orderDetail?.sales_person && (
                <Col>
                  <OrderTimeline details={orderDetail?.sales_person} />
                </Col>
              )}
            </Row>
          )}
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="order-delivery-details">
                <div className="icon-with-detail">
                  <img
                    src={deliveryIcon}
                    alt="delivery-details-icon"
                    width="30px"
                    height="30px"
                  />
                  <span className="delivery-detail">Delivery Details</span>
                </div>
                <div className="table-section">
                  <div className="heading-section">
                    <p className="main-heading delivery-srno">Sno.</p>
                    <p className="main-heading delivery-quantity">Quantity</p>
                    <p className="main-heading delivery-delivery-by">
                      Delivery by
                    </p>
                    <p className="main-heading delivery-delivery-to">
                      Deliver to
                    </p>
                    <p className="main-heading delivery-status">Status</p>
                  </div>
                  {orderDetail?.delivery_details?.length > 0 ? (
                    orderDetail?.delivery_details?.map((item) => (
                      <RecurringOrders {...item} />
                    ))
                  ) : (
                    <EmptyState
                      src={emptyState}
                      message="No recurring orders found"
                      info=""
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
          {orderDetail?.warehouse_storage_request && (
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="logistics-details">
                  <div className="icon-with-detail">
                    <img
                      src={logisticIcon}
                      alt="logistics-details-icon"
                      width="30px"
                      height="30px"
                    />
                    <span className="logistics-detail">Logistics Details</span>
                  </div>
                  <div className="info-section">
                    <div className="list">
                      <Row>
                        <Col>
                          <div className="info-flow">
                            <p className="sub-heading">Storage Duration</p>
                            <p className="info">
                              {orderDetail?.warehouse_storage_request?.duration}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          {orderDetail?.financial_assistance_request && (
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="financial-details">
                  <div className="icon-with-detail">
                    <img
                      src={financialIcon}
                      alt="financial-details-icon"
                      width="30px"
                      height="30px"
                    />
                    <span className="financial-detail">Financial Details</span>
                  </div>
                  <div className="info-section">
                    <div className="list">
                      <Row>
                        <Col md={6} lg={6} xl={6}>
                          <div className="info-flow">
                            <p className="sub-heading">Collateral</p>
                            <p className="info">
                              {
                                orderDetail?.financial_assistance_request
                                  ?.collateral
                              }
                            </p>
                          </div>
                        </Col>
                        <Col md={6} lg={6} xl={6}>
                          <div className="info-flow">
                            <p className="sub-heading">Amount Financed</p>
                            <p className="info">
                              {
                                orderDetail?.financial_assistance_request
                                  ?.amount_required
                              }
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          {orderDetail?.special_request && (
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="special-details">
                  <div className="icon-with-detail">
                    <img
                      src={specialIcon}
                      alt="special-details-icon"
                      width="30px"
                      height="30px"
                    />
                    <span className="special-detail">Special Request</span>
                  </div>
                  <div className="info-section">
                    <div className="text-area">
                      <Row>
                        <Col md={12} lg={12} xl={12}>
                          <div className="textarea-message">
                            <textarea
                              rows="4"
                              className="textarea"
                              value={orderDetail?.special_request}
                              readOnly
                            ></textarea>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(OrderDetails)
