import React, {Component} from 'react';
import {Link} from 'react-router-dom';

//images
import cancelbutton from '../../assets/Images/cancel-circle.svg';

//styles
import './styles.scss';


export default class SelectedItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true,
        };
    }

    handleCancel = () => {
        this.setState({
            visible: false,
        },()=>this.props.removeOrderItem(this.props.id));
    };

    render() {
        const {itemName, src} = this.props;

        return (
            <div className="selected-items">
                {this.state.visible ? (
                    <div className="items-selection">
                        <img className="item-image" src={src} alt={itemName} />
                        <p className="item-name">{itemName}</p>
                        <Link to="#" onClick={this.handleCancel.bind(this)}>
                            <img className="cancel" src={cancelbutton} alt="cancel-button"/>
                        </Link>
                    </div>
                ) : null}
            </div>
        );
    }
}
