//clear
export const CLEAR_COMMODITY = "CLEAR_COMMODITY"
export const CLEAR_USER = "CLEAR_USER"
export const CLEAR_LEDGERS = "CLEAR_LEDGERS"
export const CLEAR_AUTH_TOKEN = "CLEAR_AUTH_TOKEN"

//login
export const SET_USER_DETAIL = "SET_USER_DETAIL";
export const SET_USER_AUTH_TOKEN = "SET_USER_AUTH_TOKEN";

//commodity
export const SET_MASTER_COMMODITY_LIST = "SET_MASTER_COMMODITY_LIST";
export const SET_COMMODITY_LIST = "SET_COMMODITY_LIST";
export const SET_COMMODITY_FILTERS = "SET_COMMODITY_FILTERS";
export const SET_COMMODITY_FILTER_MAPPINGS = "SET_COMMODITY_FILTER_MAPPINGS";
export const SET_REQUESTED_COMMODITIES = "SET_REQUESTED_COMMODITIES";

//orders
export const SET_ORDERS_LIST = "SET_ORDERS_LIST";
export const SET_ORDERS_FILTERS = "SET_ORDERS_FILTERS";

//insights
export const SET_INSIGHTS_LIST = "SET_INSIGHTS_LIST";
export const SET_INSIGHTS_COUNT = "SET_INSIGHTS_COUNT";
export const SET_INSIGHTS_FILTERS = "SET_INSIGHTS_FILTERS";

//ledger
export const SET_LEDGER_LIST = "SET_LEDGER_LIST";
export const SET_LEDGER_FILTERS = "SET_LEDGER_FILTERS";

//addresses
export const SET_DELIVERY_DETAIL = "SET_DELIVERY_DETAIL";