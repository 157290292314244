import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import PrimaryButton from "../../buttons/PrimaryButton";
import {Link} from "react-router-dom";

//components
import RadioButton from '../../RadioButton';

//images
import greenCancelIcon from '../../../assets/Images/green-cancel-icon.svg';

//styles
import './styles.scss';


export default class FilterModal extends Component {
    constructor() {
        super();
        this.state = {
            selected: "",
            select: "",
            filters: {}
        };

        this.resetOptions = this.resetOptions.bind(this);
    }

    setSelected = (value) => (e) => {
        this.setState({selected: value})
    }

    setSelect = (value) => {
        this.setState({select: value})
    }

    resetOptions() {
        this.setState({[this.props.filterKey]: ''})
        this.props.handleFilters({[this.props.filterKey]: ''}, true)
        this.props.onHide()
    }

    setFilter = (filterValue) => (e) => {
        this.setState({[this.props.filterKey]: filterValue})
    }

    applyFilter = () => {
        let filters = {
            [this.props.filterKey]: this.state[this.props.filterKey]?.toLowerCase()
        }
        this.props.handleFilters(filters, true)
        this.props.onHide()
    }
    showDisabledButton = () => {
        return !(this.state.filterKey);

    }

    render() {
        const {show, onHide, title, secondfilterOptions, categoryHeadings, firstFilterOptions} = this.props;
        return (
            <div className="filter-modal">
                <Modal
                    show={show}
                    onHide={onHide}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="modal-section filter-modal"
                >
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                        <Link to="#" className="cancel-button" onClick={onHide}>
                            <img src={greenCancelIcon} alt="cancel-icon"/>
                        </Link>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="action-status">
                            <p className="status-heading">Filter by {categoryHeadings[0]}</p>

                            <div className="status-options">
                                {
                                    firstFilterOptions?.map(item => (
                                        <RadioButton
                                            value={item}
                                            selected={this.state[this.props.filterKey]}
                                            text={item}
                                            onChange={this.setFilter(item)}
                                            key={item.id}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                        {
                            categoryHeadings?.[1] && <div className="action-status">
                                <p className="status-heading">Filter by {categoryHeadings[1]}</p>

                                <div className="status-options">
                                    {secondfilterOptions?.map(item => {
                                        return <RadioButton
                                            value={item}
                                            selected={this.state[this.props.filterKey]}
                                            text={item}
                                            onChange={this.setFilter(item)}
                                            key={item.id}
                                        />
                                    })
                                    }
                                </div>
                            </div>}

                        <div className="action-btns">
                            {this.showDisabledButton() ? (
                                <PrimaryButton
                                    handleClick={() => this.applyFilter()}
                                    btnText="Apply"
                                />
                            ) : (
                                <button
                                    className="button-primary-disabled"
                                    disabled
                                >
                                    Apply
                                </button>
                            )}
                            <Link to="#" className="status-heading reset" onClick={this.resetOptions}>Reset</Link>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
