import React , {Component} from 'react';

//styles
import './styles.scss';


export default class OfficesLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  render() {
    const { src, name, address } = this.props

    return ( 
      <div className="offices-locations">
        <img className="office-img" src={src} alt="office" />
        
        <div className="address">
          <h3 className="name">{name}</h3>
          <p className="address-info">{address}</p>
        </div>
      </div>
    );
  }
}
