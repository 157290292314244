import React , {Component} from 'react';

//styles
import './styles.scss';

export default class RecurringOrders extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { id, quantity, scheduled_date,
            contact_person, state, is_a_return } = this.props

        return ( 
            <div className="recurring-orders-table-row">
                <div className="order-list-name">
                    <div className="order-serial-number listing">
                        <p className="serial-number info">{id}</p>
                    </div>
                    <div className="order-quantity listing">
                        <p className={"quantity info " + (is_a_return && "text-red")}>{is_a_return && '-'}{quantity}</p>
                    </div>
                    <div className="order-deliver-by listing">
                        <p className="date info">{scheduled_date.split(" ")[0]}</p>
                    </div>
                    <div className="order-deliver-to listing">
                        <p className="type info">{contact_person?contact_person:'-'}</p>
                    </div>
                    <div className="order-status listing">
                        <button 
                            className={`outlines ${state === 'dispatched' ? 'red' : state === 'delivered' ? 'green' : 'orange'}`}
                        >
                            {state}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
