import React, {Component} from 'react';

//image
import downArrow from '../../../assets/Images/down-arrow.svg';

//styles
import './styles.scss';

export default class SelectBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listOpen: false,
            selectedValue: this.props.selectedValue?.length > 0 ? this.props.selectedValue : null,
        };
    }

    toggleList() {
        this.setState(prevState => ({
            listOpen: !prevState.listOpen
        }))
    }

    itemSelected(index) {
        let temp = this.props.list[index]
        this.setState({
            selectedValue: temp,
            listOpen: false,
        }, () => this.props.onSelect(this.state.selectedValue))
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedVariant?.id !== this.props.selectedVariant?.id)
            this.setState({selectedValue: this.props.selectedVariant})
    }

    componentDidMount() {
        document.body.addEventListener('click', this.handleOutsideClick);
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.handleOutsideClick);
    }

    handleOutsideClick = (e) => {
        if (e.target.value === undefined) {
            this.setState({listOpen: false});
        }
    }

    render() {
        const {listOpen, selectedValue} = this.state
        const {title, list} = this.props

        return (
            <div className="select-box">
                <div className="list-header"
                     onClick={() => this.toggleList()}
                     style={listOpen === true ? {boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.15)'} : null}
                >
                    <span className={`${selectedValue ? 'header-title' : 'title'}`}>
                        {selectedValue ? selectedValue.attribute : title}
                    </span>
                    {listOpen
                        ? <img src={downArrow} alt="down-arrow" className="dropdown-icons up-arrow"/>
                        : <img src={downArrow} alt="down-arrow" className="dropdown-icons"/>
                    }
                </div>
                {listOpen && <ul className="list">
                    {list.map((item, index) => (
                        <li
                            className="list-item"
                            key={item.id}
                            onClick={() => this.itemSelected(index)}>
                            {item.attribute}
                            <br/>
                        </li>
                    ))}
                </ul>}
            </div>
        );
    }
}
