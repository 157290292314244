import React, {Component} from 'react';

//styles
import './styles.scss';


export default class OutlineButton extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {btnText, handleClick, leftIconActive, leftIcon, leftSrc} = this.props;

        return (
            <div className="outline">
                <button className="button-outline" onClick={handleClick}>
                    {leftIconActive && (
                        <span className="icon-img">
              <img src={leftIcon} alt="filter"/>
                            {
                                leftSrc && <span className="red-dot-icon"><img src={leftSrc} alt="left-icon"/></span>
                            }
            </span>
                    )}
                    {btnText}
                </button>
            </div>
        )
    }
}
