import React, {useEffect, useState} from 'react';

import {Pagination} from 'react-bootstrap';

//styles
import "./styles.scss";

const ITEMS_PER_PAGE = 10;

const PaginationCount = React.memo(({dataCount, handlePageChange}) => {
        const [activePage, setActivePage] = useState(1)
        const [totalPages, setTotalPages] = useState([])

        useEffect(() => {
            let pages = getPageNumber(dataCount, ITEMS_PER_PAGE)
            setTotalPages(pages)
        }, [dataCount])

        if (!dataCount)
            return []
        return (
            <Pagination>
                {activePage !== totalPages[0] && <Pagination.Prev onClick={() => {
                    setActivePage(activePage - 1)
                    handlePageChange(activePage - 1)
                }}/>}
                {
                    totalPages.map(item => {
                        return (
                            <Pagination.Item active={item === activePage}
                                             onClick={() => {
                                                 if (item !== activePage) {
                                                     setActivePage(item)
                                                     handlePageChange(item)
                                                 }
                                             }}>{item}</Pagination.Item>
                        )
                    })
                }

                {activePage !== totalPages[totalPages.length - 1] && <Pagination.Next onClick={() => {
                    setActivePage(activePage + 1)
                    handlePageChange(activePage + 1)
                }}/>}
            </Pagination>
        );
    }
)

const getPageNumber = (dataCount, itemsPerPage) => {
    let pages = []
    for (let i = 1; i <= Math.ceil(dataCount / itemsPerPage); i++) {
        pages.push(i)
    }
    return pages
}


export default PaginationCount;
