import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//icons
import { FaAngleLeft } from "react-icons/fa";

//images
import mobileNumber from '../../../assets/Images/phone.svg';
import logo from '../../../assets/Images/bitmap.png';
import cart from '../../../assets/Images/cart.svg';

//styles
import './styles.scss';

export default class OrderTimeline extends Component {

  render() {
    const { onClick, details } = this.props

    return (
      <div className="order-timeline">
        <div className="action-btn">
          <div className="back-button">
            <Link to="#" onClick={onClick} className="back-btn">
              <span className="icon-left"><FaAngleLeft /></span>
              Back
            </Link>
          </div>
          <div className="mobile-cart">
            <img className="cart" src={cart} alt="cart" />
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="top-bar">
          <h6 className="timline-heading">Order Status</h6>
        </div>
        <div className="orders-timeline-listing">
          <div className="contact-section">
            <p className="sales-person">Sales Person</p>
            <div className="sales-contact">
              <div>
                <p className="sales-name">{details?.name}</p>
                <Link to="#" className="sales-number" >
                  <span className="phone-icon"><img src={mobileNumber} alt="phone-icon" /></span>{details?.phone ? details.phone : '-'}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
