import React , {Component} from 'react';

//styles
import './styles.scss';


export default class EmptyState extends Component {
  render() {
    const { message, src, info } = this.props;

    return ( 
      <div className="empty-state">
        <img className="empty-image" src={src} alt="empty-state" />
        <p className="message mb-0">
          {message}
        </p>
        <p className="message mt-0 mb-0 info">{info}</p>
      </div>
     );
  }
}
