import React, { Component } from "react"

//component
import SelectBox from "../../formFields/SelectBox"
import Accordian from "../../Accordian"
import InputNumber from "../../formFields/InputNumber"

//images
import financial from "../../../assets/Images/financial.svg"

//styles
import "./styles.scss"

export default class PaymentDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      deliveryDetails: {
        collateral: "warehouse_receipt",
        amount_required: 1,
      },
      collateral: [
        {
          id: 0,
          attribute: "Warehouse Receipt",
          key: "warehouse_receipt",
        },
        {
          id: 1,
          attribute: "Payment Receipt",
          key: "payment_receipt",
        },
      ],
    }
    this.trackAccordionClickEvent = this.trackAccordionClickEvent.bind(this)
  }

  trackAccordionClickEvent() {
    if (typeof window !== "undefined" && window.gtag != null) {
      window.gtag("set", "event", { action: "financial_assistance" })
    }
  }

  setAmount = (value) => {
    const deliverDetails = Object.assign({}, this.state.deliverDetails)
    deliverDetails.amount_required = value
    this.setState({ deliverDetails }, () =>
      this.props.handleChange(deliverDetails)
    )
  }

  setCollateral = (value) => {
    const deliverDetails = Object.assign({}, this.state.deliverDetails)
    deliverDetails.collateral = value.key
    this.setState({ deliverDetails }, () =>
      this.props.handleChange(deliverDetails)
    )
  }

  render() {
    return (
      <div className="payment-details">
        <Accordian
          src={financial}
          question="Looking for Financial Assistance?"
          questionInfo="We've got you covered! We can offer you financial assistance based on eligibility."
          list={this.state.collateral}
          onClick={this.trackAccordionClickEvent}
          body={
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 amount-required">
                <p className="status">Amount Required</p>
                <InputNumber
                  placeholder="Amount Required"
                  handleChange={this.setAmount}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 collateral">
                <p className="status">Select Collateral</p>
                <SelectBox
                  title="Select Collateral"
                  list={this.state.collateral}
                  onSelect={this.setCollateral}
                />
              </div>
            </div>
          }
        />
      </div>
    )
  }
}
