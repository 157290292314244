import React, { Component } from "react"
import { Col, Row } from "react-bootstrap"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"

//component
import ProfileDrawer from "../../components/ProfileDrawer"

//utils
import { BASE64_PREFIX } from "../../utilities/common/constants"

//icons
import { FiChevronDown, FiChevronUp } from "react-icons/fi"

//images
import cart from "../../assets/Images/cart.svg"

//styles
import "./styles.scss"

class Header extends Component {
  state = {
    profileActive: false,
  }

  profileActiveShow = () => {
    this.setState({ profileActive: !this.state.profileActive })
    if (!this.state.profileActive) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }

  cartShow = () => {
    this.props.history.push("/service-request-form")
  }

  render() {
    const { profileActive } = this.state
    const { userDetail } = this.props

    return (
      <div className="header">
        <Row>
          <Col xs={12} sm={12} md={3} lg={3} xl={3}>
            <div className="dehaat-logo">
              <Link to="/home-page" className="logo-heading">
                DeHaat
              </Link>
            </div>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="nav-bar">
              <Link className="nav-link commodities" to="/home-page">
                <svg
                  className="svg-icon"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 23"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Symbols"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g id="Group-15">
                      <ellipse
                        id="Oval-Copy"
                        className="svg-image-circle"
                        cx="12"
                        cy="11.5"
                        rx="12"
                        ry="11.5"
                      ></ellipse>
                      <path
                        d="M11.9983062,8.60371073 C12.5753867,7.91232646 13.2763565,7.32497486 14.0683739,6.8691744 C13.5365394,5.99239103 12.8334819,5.22196981 11.9976102,4.6 C11.1617446,5.22196608 10.4586982,5.99238848 9.92688258,6.8691744 C10.7194073,7.32482019 11.4208496,7.91218299 11.9983062,8.60371073 L11.9983062,8.60371073 Z M12.7997522,9.4899308 C13.2461368,9.82907487 13.6467703,10.2201115 13.9924262,10.6540355 C14.8262378,10.2561372 15.7222497,9.99138292 16.645097,9.8702224 C16.4268017,9.00001363 16.0192049,8.18379337 15.448985,7.47498562 C14.3970539,7.91276658 13.4835148,8.6075833 12.7997522,9.4899308 Z M17.9949822,10.9296029 C16.4279616,10.9067224 14.8943559,11.3645898 13.6181601,12.2363298 C13.2308949,11.4999901 12.6772993,10.8555916 11.9976102,10.3499712 C11.317334,10.8560332 10.7633771,11.5011067 10.3760703,12.2382273 C9.10048611,11.3654623 7.56730292,10.9060776 6.00013021,10.9270729 C5.98916496,12.3717965 6.67127057,13.7412866 7.85027496,14.6416808 C8.86112474,15.4405965 10.0945639,15.9373937 11.3974572,16.0703934 L11.3974572,17.8248649 C11.3974572,18.142427 11.666085,18.399862 11.9974542,18.399862 C12.3288234,18.399862 12.5974512,18.142427 12.5974512,17.8248649 L12.5974512,16.0740446 C13.901443,15.9423342 15.13626,15.4461548 16.1483235,14.647218 C17.3275429,13.7459957 18.0085687,12.3749833 17.9949822,10.9296029 L17.9949822,10.9296029 Z M11.1952582,9.49007455 C10.511511,8.60763608 9.59792397,7.91276168 8.54591748,7.47498562 C7.97598519,8.18339331 7.56851991,8.99912186 7.35017146,9.86881941 C8.27287768,9.99080728 9.16860693,10.2563679 10.0020022,10.6550187 C10.3477701,10.2207556 10.7486043,9.82943187 11.1952582,9.49007455 Z"
                        id="artichoke-copy"
                        className="svg-image"
                        fillRule="nonzero"
                      ></path>
                    </g>
                  </g>
                </svg>

                <p className="nav-name">Your Commodities</p>
              </Link>

              <Link className="nav-link orders" to="/order-listing">
                <svg
                  className="svg-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g
                    className="svg-image-circle"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g>
                      <g>
                        <path
                          className="svg-image-circle"
                          d="M201 10c6.627 0 12 5.373 12 12s-5.373 12-12 12-12-5.373-12-12 5.373-12 12-12zm-.042 6c-1.095 0-1.983.888-1.983 1.983h-1.983c-.547.002-.99.445-.992.992v7.933c.002.547.445.99.992.992h7.933c.547-.002.99-.445.992-.992v-7.933c-.002-.547-.445-.99-.992-.992h-1.983c0-1.095-.888-1.983-1.984-1.983zm2.48 8.43c.273 0 .495.221.495.495s-.222.496-.495.496h-4.959c-.274 0-.496-.222-.496-.496s.222-.496.496-.496h4.958zm0-2.976c.273 0 .495.222.495.496s-.222.496-.495.496h-4.959c-.274 0-.496-.222-.496-.496s.222-.496.496-.496h4.958zm-2.48-4.462c.548 0 .992.444.992.991 0 .548-.444.992-.992.992-.547 0-.991-.444-.991-.992 0-.547.444-.991.991-.991z"
                          transform="translate(-579 -10) translate(390)"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
                <p className="nav-name">Order History</p>
              </Link>

              <Link className="nav-link ledgers" to="/ledger">
                <svg
                  className="svg-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g
                    className="svg-image-circle"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g>
                      <g>
                        <path
                          className="svg-image-circle"
                          d="M323 10c6.627 0 12 5.373 12 12s-5.373 12-12 12-12-5.373-12-12 5.373-12 12-12zm-2.75 7h-.416c-.222 0-.434.088-.59.244-.156.156-.244.368-.244.59v8.333c0 .46.373.833.833.833h.417V17zm6.25 0h-5.416v10h5.416c.46 0 .833-.373.833-.833v-8.333c0-.222-.087-.434-.243-.59-.157-.156-.369-.244-.59-.244zm-.729 5c.172 0 .313.188.313.417 0 .229-.14.416-.313.416h-3.125c-.172 0-.312-.187-.312-.416 0-.23.14-.417.312-.417h3.125zm0-2.083c.172 0 .313.187.313.416 0 .23-.14.417-.313.417h-3.125c-.172 0-.312-.188-.312-.417 0-.229.14-.416.312-.416h3.125z"
                          transform="translate(-701 -10) translate(390)"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
                <p className="nav-name">Ledger</p>
              </Link>
              <Link className="nav-link insights" to="/insights">
                <svg
                  className="svg-icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    clipPath="url(#clip0)"
                    className="svg-image-circle"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <path d="M23.9776 11.273C23.7815 4.91947 18.8625 0.0110474 12.6999 0.0110474H11.9946V12H24L23.9776 11.273Z" />
                    <path d="M10.5785 4.24243H9.87325C4.42914 4.24243 0 8.67153 0 14.1157C0 19.5598 4.42914 23.9889 9.87325 23.9889C15.3174 23.9889 19.7465 19.5598 19.7465 14.1157V13.4105H10.5785V4.24243ZM4.93662 10.5895H7.05232V12H4.93662V10.5895ZM7.75755 14.8209H3.52616V13.4105H7.75755V14.8209Z" />
                  </g>
                </svg>
                <p className="nav-name">Insights</p>
              </Link>
            </div>
          </Col>

          <Col xs={12} sm={12} md={1} lg={1} xl={1}>
            <div className="cart-section">
              <Link className="nav-link cart" to="/service-request-form">
                <img className="cart" src={cart} alt="cart" />
              </Link>
            </div>
          </Col>

          <Col xs={12} sm={12} md={2} lg={2} xl={2}>
            <div className="details-section" onClick={this.profileActiveShow}>
              <div className="company-details">
                {userDetail?.company?.image ? (
                  <img
                    className="company-logo"
                    src={BASE64_PREFIX + userDetail?.company?.image}
                    alt="company-logo"
                  />
                ) : (
                  <></>
                )}
                <div className="company-assets">
                  <p className="company-name">
                    {userDetail?.company?.name.length > 8
                      ? `${userDetail?.company?.name.substring(0, 8)}...`
                      : userDetail?.company?.name}
                  </p>
                </div>
              </div>

              <div className="dropdown-icon">
                {!profileActive ? <FiChevronDown /> : <FiChevronUp />}
              </div>
            </div>
          </Col>
        </Row>

        {profileActive ? (
          <ProfileDrawer profileActiveShow={this.profileActiveShow} />
        ) : null}
      </div>
    )
  }
}

export default withRouter(
  connect(({ user }) => ({ ...user }), { })(Header)
)
