import React, { Component } from "react"
import { withRouter } from "react-router-dom"

//components
import Header from "../../components/Header"
import PrimaryButton from "../../components/buttons/PrimaryButton"
import OutlineButton from "../../components/buttons/OutlineButton"
import ItemsDescription from "../../components/ItemsDescription"
import MobileHeader from "../../components/MobileHeader"
import SidebarNavigation from "../../components/SidebarDrawer"
import Loader from "../../components/Loader"

//utils
import LocalStorage from "../../utilities/localStorage"

//images
import filterIcon from "../../assets/Images/filter-icon.svg"
import redDot from "../../assets/Images/red-dot.svg"
import cancelIcon from "../../assets/Images/red-cancel-icon.svg"

//styles
import "./styles.scss"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as commodityActions from "../../utilities/Store/actions/commodity"
import isEmpty from "lodash/isEmpty"
import emptyState from "../../assets/Images/empty-state.svg"
import EmptyState from "../../components/EmptyState"

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      quantity: 0,
      header: false,
      filterActive: false,
      commodityList: [],
      selectedCommodities: [],
    }
  }

  captureAddToCartEvent() {
    if (typeof window !== "undefined" && window.gtag != null) {
      window.gtag("event", "click", { action: "add_to_cart" })
    }
  }

  captureCommodityToggle(checked) {
    if (typeof window !== "undefined" && window.gtag != null) {
      window.gtag("event", "click", {
        action: checked
          ? "home_page_commoditiy_added"
          : "home_page_commodity_removed",
      })
    }
  }

  FilterActiveShow = () => {
    this.setState({ filterActive: !this.state.filterActive })

    if (!this.state.filterActive) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }

  closeFilterActive = () => {
    this.setState({ filterActive: false })

    if (this.state.filterActive) {
      document.body.style.overflow = "unset"
    }
  }

  async componentDidMount() {
    document.getElementsByTagName("body")[0].className = "page-home"
    window.scrollTo(0, 0)
    this.fetchCommodities()
  }

  fetchCommodities = async (payload) => {
    let filters
    if (payload && payload.filters) filters = payload.filters
    else filters = { ...this.props.commodityFilters }
    let res = {}
    try {
      this.setState({ loading: true })
      res = await this.props.getCommodityList(filters)
      if (res?.error === null && !isEmpty(res?.data?.product_details)) {
        this.setState({
          commodityList: res?.data?.product_details,
          loading: false,
        })
      } else this.setState({ loading: false })
    } catch (e) {
      this.setState({ loading: false })
    }
  }

  handleFilters = (filterUpdates, fetchData) => {
    this.props.updateCommodityFilters(filterUpdates)
    let filters = { ...this.props.commodityFilters, ...filterUpdates }
    if (fetchData) {
      clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        this.fetchCommodities({ filters })
      }, 400)
    }
  }

  removeFilter = (id) => {
    let removedFilters = this.props.commodityFilters?.productCategories.filter(
      (categoryId) => categoryId !== id
    )
    this.handleFilters({ productCategories: removedFilters }, true)
  }

  toggleSelectedCommodity = (checked, id) => {
    this.captureCommodityToggle(checked)
    let { selectedCommodities, quantity } = this.state
    if (checked) {
      quantity++
    } else {
      quantity--
    }
    if (selectedCommodities.includes(id)) {
      let index = selectedCommodities?.indexOf(id)
      if (index > -1) {
        selectedCommodities.splice(index, 1)
      }
    } else selectedCommodities = [...selectedCommodities, id]
    this.setState(
      {
        selectedCommodities,
        quantity,
      },
      () => this.updateSelectedCommodity(this.state.selectedCommodities)
    )
  }

  updateSelectedCommodity = (commodityIds) => {
    let selectedCommodity =
      this.props.commodityList?.filter((item) =>
        commodityIds?.includes(item.id)
      ) || []
    this.props.updateRequestedCommodities(selectedCommodity)
    LocalStorage.set.selectedCommodities(JSON.stringify(selectedCommodity))
  }

  handleOrderRequest = (commodityIds) => {
    this.captureAddToCartEvent()
    this.updateSelectedCommodity(commodityIds)
    this.props.history.push("/service-request-form")
  }

  render() {
    const { quantity, filterActive, commodityList } = this.state
    return (
      <div className="home-page">
        {this.state.loading && <Loader />}

        <div className="main-header-section">
          <Header />
        </div>

        <MobileHeader />

        <div className="info-section">
          {quantity < 1 ? (
            <div>
              <div>
                <h3 className="welcome-name">
                  Welcome {this.props.user?.userDetail?.company?.name}!
                </h3>
                <p className="welcome-info">
                  Here is an exclusive list of your interests. You can checkout
                  commodity details or further expand your interests.
                </p>
              </div>

              <div className="welcome-section">
                <div className="search-section">{/* <Searchbar/> */}</div>

                <div className="sticky-button">
                  <OutlineButton
                    handleClick={this.FilterActiveShow}
                    btnText="Filter categories"
                    leftIconActive={true}
                    leftIcon={filterIcon}
                    leftSrc={
                      this.props.commodityFilters?.productCategories?.length > 0
                        ? redDot
                        : false
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="request-all-section">
              <div className="selected-section">
                <h3 className="welcome-name">
                  You Selected {quantity}{" "}
                  {quantity === 1 ? "Commodity!" : "Commodities!"}
                </h3>
                <p className="welcome-info multiple-commodities">
                  Go ahead and select multiple commodities in one go.
                </p>
              </div>
              <div className="sticky-button">
                <PrimaryButton
                  handleClick={() => {
                    this.captureAddToCartEvent()
                    this.props.history.push("/service-request-form")
                  }}
                  btnText="Request Selected Commodity(ies)"
                />
              </div>
            </div>
          )}
        </div>

        <div className="catelogue-info">
          {this.props.commodityFilters?.productCategories?.length > 0 && (
            <div className="filter-heading">
              <p className="filter-applied-info">Showing results for</p>
              <div className="filter-names">
                {this.props.commodityFilters?.productCategories?.map((id) => {
                  return (
                    <PrimaryButton
                      handleClick={() => this.removeFilter(id)}
                      btnText={this.props.commodityCategoryMap?.[id]}
                      rightIconActive={true}
                      rightIcon={cancelIcon}
                      key={this.props.commodityCategoryMap?.[id]}
                    />
                  )
                })}
              </div>
            </div>
          )}
          <div className="item-desc">
            {!isEmpty(commodityList) ? (
              commodityList?.map((item) => {
                return (
                  <ItemsDescription
                    id={item.id}
                    label={item.name}
                    src={item.product_image_url}
                    info={item.description}
                    variants={item.variants}
                    unitOfMeasurement={item.uom.name}
                    onClick={this.toggleSelectedCommodity}
                    handleOrderRequest={this.handleOrderRequest}
                    key={item.id}
                  />
                )
              })
            ) : (
              <div style={{ width: "100%" }}>
                <EmptyState
                  src={emptyState}
                  message="No commodities found"
                  info=""
                />
              </div>
            )}
          </div>
        </div>

        {filterActive ? (
          <div onClick={this.closeFilterActive} className="filter-categories">
            <div className="backdrops" />
            <SidebarNavigation
              handleFilters={this.handleFilters}
              clearWebIcon={true}
              onClick={this.closeFilterActive}
            />
          </div>
        ) : null}
      </div>
    )
  }
}

const connectedComponent = connect(
  ({ commodity, user }) => ({
    commodityList: commodity.list,
    commodityFilters: commodity.filters,
    commodityCategoryMap: commodity?.filters?.commodityCategoryMap,
    requestedCommodities: commodity?.requestedCommodities,
    user,
  }),
  (dispatch) => bindActionCreators({ ...commodityActions }, dispatch)
)(Home)

export default withRouter(connectedComponent)
