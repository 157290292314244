import React, { useEffect } from "react"
import { Redirect, Route } from "react-router-dom"
import keycloak from "../utilities/keycloak/config"

const trackPageView = (path) => {
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("event", "pageview", {
      page_title: path.split("/").filter(Boolean).join("-") || "Log in",
      page_location: window.location.href,
      page_path: path,
    })
  }
}

function ProtectedRoute({ component: Component, authed, location, ...rest }) {
  useEffect(() => {
    trackPageView(location.pathname)
  }, [location.pathname])

  return (
    <Route
      location={location}
      {...rest}
      render={(props) => {
        if (keycloak.token) return <Component {...props} />
        return (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }}
    />
  )
}

export default ProtectedRoute
