import React, { Component } from "react";
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

//components
import OrderItem from '../../orders/OrderItem';
import RadioButton from '../../RadioButton';
import PrimaryButton from '../../buttons/PrimaryButton';
import Textarea from '../../formFields/Textarea';

//images
import potato from '../../../assets/Images/potato.png';
import greenCancelIcon from '../../../assets/Images/green-cancel-icon.svg';

//styles
import './styles.scss';


export default class FlagModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flagshow: false,
      selected: "",
    };
    this.handleFlagShow = this.handleFlagShow.bind(this);
    this.handleFlagClose = this.handleFlagClose.bind(this);
  }

  handleFlagClose() {
    this.setState({ show: false, flagshow: false, successShow: false });
  }

  handleFlagShow() {
    this.setState({ flagshow: true });
  }

  setSelected = (value) => {
    this.setState({ selected: value })
  }

  render() {
    const { selected } = this.state;
    const { show, handleClick, btnText, status, onHide } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-section flag-modal"
      >
        <Modal.Header>
          <Modal.Title>Flag an Issue</Modal.Title>
          <Link to="#" className="cancel-button" onClick={onHide}>
            <img src={greenCancelIcon} alt="cancel-icon" />
          </Link>
        </Modal.Header>

        <Modal.Body>
          <div className="desc-box">
            <div className="content-box">
              <div className="date-qr-code">
                <p className="order-detail">Order Details</p>
                <p className="qr-code">OR_0013 <span className="date-with-space">|</span><span className="date-with-space">17/05/2020</span></p>
              </div>

              <div className="list-box">
                <OrderItem
                  src={potato}
                  commodityName="Potato"
                  commodityInfo="Kufri Lalima"
                  btnText="Ongoing"
                  status="70% complete"
                />
              </div>

              <div className="mobile-orders-flow">
                <div className="order-image">
                  <img className="order-img" src={potato} alt="order-list" />
                  <p className="heading">Potato <span className="sub-name">(Kufri Lalima)</span></p>
                </div>

                <div className="orders-info">
                  <div className="order-type">
                    <p className="type">Recurring</p>
                    <p className="times">12 times</p>
                  </div>

                  <div className="order-amount">
                    <p className="amount">
                      <span className="rupees-icon">₹</span>12000.00
                    </p>
                    <p className="kgs">@ ₹34.00/kg</p>
                  </div>

                  <div className="order-status">
                    <button
                      className={`outlines ${btnText === 'In Review' ? 'red' : btnText === 'Completed' ? 'green' : 'orange'}`}
                    >
                      {btnText}
                    </button>
                    <p className="status">{status}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="category-selection">
            <h3 className="category">Select a category</h3>
            <p className="category-info">Tell us your issue about this order and we’ll get in touch.</p>
            <RadioButton
              value="first"
              selected={selected}
              text="Delivery related"
              onChange={this.setSelected}
            />

            <RadioButton
              value="second"
              selected={selected}
              text="Payment"
              onChange={this.setSelected}
            />

            <RadioButton
              value="third"
              selected={selected}
              text="Order details"
              onChange={this.setSelected}
            />

            <RadioButton
              value="fourth"
              selected={selected}
              text="Logistics"
              onChange={this.setSelected}
            />

            <RadioButton
              value="fifth"
              selected={selected}
              text="Other"
              onChange={this.setSelected}
            />

            <Textarea placeholder="Describe your concern.." />
          </div>

          <div className="action-btns">
            <PrimaryButton
              handleClick={handleClick}
              btnText="Submit"
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
