import React from "react"

//components
import Header from "../../components/Header"
import MobileHeader from "../../components/MobileHeader"
import Loader from "../../components/Loader"
import SelectBox from "../../components/formFields/SelectBox"

//styles
import "./styles.scss"
import PaginationCount from "../../components/Pagination"
import EmptyState from "../../components/EmptyState"
import { Col, Row } from "react-bootstrap"
import InsightItem from "../../components/insights/InsightItem"
import emptyState from "../../assets/Images/empty-state.svg"

//styles
import "./styles.scss"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { get, URL } from "../../utilities/network"

// Redux
import * as insightsActions from "../../utilities/Store/actions/insights"

class Insights extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      dataCount: null,
      insightList: [],
      filterObj: {},
    }
    this.timer = null
  }

  componentDidMount() {
    document.getElementsByTagName("body")[0].className = "page-insights"
    window.scrollTo(0, 0)
    this.fetchAndPopulateList()
    this.fetchInsights(this.props.insightsFilters)
  }

  fetchAndPopulateList = async () => {
    const response = await get(URL.insights.filters)
    this.setState({
      filterObj: response.data,
    })
  }

  createDropDownList(list) {
    const ddList = list.map((ele, idx) => ({
      id: idx + 1,
      attribute: ele,
      selected: false,
      value: ele,
    }))
    ddList.splice(0, 0, {
      id: 0,
      attribute: "Select all",
      selected: true,
      value: null,
    })
    return ddList
  }

  fetchInsights = async (payload) => {
    let filters
    if (payload && payload.filters) filters = payload.filters
    else filters = { ...this.props.insightsFilters }
    let res = {}
    try {
      this.setState({ isLoading: true })
      res = await this.props.getInsightsList(filters)
      if (res?.error === null) {
        this.setState({
          insightList: res.data || [],
          isLoading: false,
          dataCount: res.count || null,
        })
      } else this.setState({ isLoading: false })
    } catch (e) {
      this.setState({ isLoading: false })
    }
  }

  handlePageChange = (page) => {
    if (page) {
      let filterUpdates = {
        offset: page * 10 - 10,
      }
      this.handleFilters(filterUpdates, true, "offset")
    }
  }

  handleFilters = (filterUpdates, fetchData) => {
    this.props.updateFilters(filterUpdates)
    let filters = { ...this.props.insightsFilters, ...filterUpdates }
    if (fetchData) {
      clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        this.fetchInsights({ filters })
      }, 400)
    }
  }

  render() {
    return (
      <div className="insights-container">
        {this.state.isLoading && <Loader />}
        <div className="main-header-section">
          <Header />
        </div>
        <MobileHeader />
        <div className="info-section">
          <Row>
            <Col xs={12}>
              <div className="mobile-heading-updates">
                <h1 className="insights">Insights</h1>
              </div>
              <div className="insight-section">
                <div className="main-listing">
                  <h4 className="insights">Insights</h4>
                  <div className="filter-section">
                    {this.state.filterObj["states"] && (
                      <SelectBox
                        title="Filter State"
                        list={this.createDropDownList(
                          this.state.filterObj["states"]
                        )}
                        onSelect={(selectedOption) => {
                          let filter = {
                            states: selectedOption.value,
                          }
                          this.handleFilters(filter, true, "state")
                        }}
                      />
                    )}
                    {this.state.filterObj["analysis_type"] && (
                      <SelectBox
                        title="Filter type"
                        list={this.createDropDownList(
                          this.state.filterObj["analysis_type"]
                        )}
                        onSelect={(selectedOption) => {
                          let filter = {
                            types: selectedOption.value,
                          }
                          this.handleFilters(filter, true, "type")
                        }}
                      />
                    )}
                    {this.state.filterObj["seasons"] && (
                      <SelectBox
                        title="Filter Season"
                        list={this.createDropDownList(
                          this.state.filterObj["seasons"]
                        )}
                        onSelect={(selectedOption) => {
                          let filter = {
                            seasons: selectedOption.value,
                          }
                          this.handleFilters(filter, true, "season")
                        }}
                      />
                    )}
                    {this.state.filterObj["crops"] && (
                      <SelectBox
                        title="Filter Crop"
                        list={this.createDropDownList(
                          this.state.filterObj["crops"]
                        )}
                        onSelect={(selectedOption) => {
                          let filter = {
                            crops: selectedOption.value,
                          }
                          this.handleFilters(filter, true, "crop")
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="table-section">
                  <div className="heading-section">
                    <p className="main-heading insight-name">Name</p>
                    <p className="main-heading insight-image" />
                    <p className="main-heading insight-download">Download</p>
                  </div>
                  {(this.state.insightList || []).length > 0 ? (
                    this.state.insightList.map((item) => (
                      <InsightItem insightDetail={item} key={item.sr_no} />
                    ))
                  ) : (
                    <EmptyState
                      src={emptyState}
                      message="No Insights found"
                      info=""
                    />
                  )}
                </div>
              </div>
              <PaginationCount
                dataCount={this.state.dataCount}
                handlePageChange={this.handlePageChange}
              />
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ insights }) => {
    return {
      ...insights,
      insightsFilters: insights.filters,
    }
  },
  (dispatch) => bindActionCreators({ ...insightsActions }, dispatch)
)(Insights)
