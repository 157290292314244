import React, {Component} from 'react';
import {Link} from "react-router-dom";

//styles
import './styles.scss';


export default class PrimaryButton extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {btnText, rightIcon, rightIconActive, handleClick, loading, ...restProps} = this.props;

        return (
            <div className="primary">
                <button className="button-primary" onClick={handleClick} {...restProps}>
                    {btnText}
                    {loading && <div className="spinner-border loader"
                                     role="status">
                        <span className="sr-only">Loading...</span>
                    </div>}
                    {rightIconActive && (
                        <span>
                            <Link to="#" className="icon-img">
                                <img src={rightIcon} alt="right-icon"/>
                            </Link>
                        </span>
                    )}
                </button>
            </div>
        )
    }
}
