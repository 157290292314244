import React, { Component } from "react"
import { withRouter } from "react-router-dom"

//component
import PrimaryButton from "../../components/buttons/PrimaryButton"

//styles
import "./styles.scss"

class ItemsDescription extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isChecked: false,
    }
  }

  toggleCheck = () => {
    this.props.onClick(!this.state.isChecked, this.props.id)
    this.setState({ isChecked: !this.state.isChecked })
  }

  render() {
    const {
      label,
      src,
      info,
      id,
      variants = [],
    } = this.props
    const attrVariants = variants.filter((ele) => ele.attribute)
    return (
      <div
        key={id}
        className="home-items-description"
        style={
          this.state.isChecked === true
            ? { boxShadow: "0 11px 22px 0 rgba(42, 42, 40, 0.12)" }
            : null
        }
      >
        <div className="content">
          <div className="desc">
            <div className="desc-section-container">
              <div className="checkbox" onClick={this.toggleCheck}>
                <input
                  type="checkbox"
                  checked={this.state.isChecked}
                />
                <span />
                <label className="label-text">{label}</label>
              </div>
              <p className="para">{info}</p>
            </div>
            <img className="item-image" src={src} alt={label} />
          </div>

          <div className="info">
            <p className="sub-heading">
              {attrVariants.length > 0
                ? attrVariants.length === 1
                  ? `${attrVariants.length} variety available`
                  : `${attrVariants.length} varieties available`
                : "No varieties available"}
            </p>
            <div className="varieties-section">
              {(attrVariants|| []).map((variant) => (
                <div key={variant.id} className="varieties">
                  <p className="varities-name">{variant.attribute}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="price-section">
          {this.state.isChecked === false ? (
            <PrimaryButton
              btnText="Add to Cart"
              handleClick={(e) => {
                this.props.handleOrderRequest([id])
                e.stopPropagation()
              }}
            />
          ) : (
            <button className="button-primary-disabled" disabled>
              Added
            </button>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(ItemsDescription)
