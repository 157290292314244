import { get, URL } from "../../network"
import isEmpty from "lodash/isEmpty"
import * as Actions from "./actionTypes"
import { createQueryParamUrl } from "../../helpers"
import { USER_DISABLE_ERROR } from "../../common/constants"
import LocalStorage from "../../localStorage"
import { clearUserDetails } from "./user"
import keycloak from "../../../utilities/keycloak/config"

const handleError = (err) => {
  console.log("Error in commodity action : ", err)
  return err.response || err
}

export const getCommodityList = (filters) => async (dispatch) => {
  try {
    let url = generateFilterUrl(URL.commodity.list, filters)
    const response = await get(url)
    if (response?.error === null && !isEmpty(response.data)) {
      dispatch({
        type: Actions.SET_COMMODITY_LIST,
        data: response?.data?.product_details,
      })
      if (url === URL.commodity.list) {
        dispatch({
          type: Actions.SET_MASTER_COMMODITY_LIST,
          data: response?.data?.product_details,
        })
        setCommodityFilterMappings(response?.data?.product_details, dispatch)
      }
      return response
    } else if (response?.error === USER_DISABLE_ERROR) handleLogout()
    return null
  } catch (err) {
    return handleError(err)
  }
}

export const updateCommodityFilters = (filters) => async (dispatch) => {
  dispatch({
    type: Actions.SET_COMMODITY_FILTERS,
    data: filters,
  })
}

export const updateRequestedCommodities = (data) => async (dispatch) => {
  dispatch({
    type: Actions.SET_REQUESTED_COMMODITIES,
    data,
  })
}

export const clearCommodities = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_COMMODITY,
    data: {},
  })
}

const generateFilterUrl = (sourceUrl, filters) => {
  let mappings = {},
    params = [],
    url = ""
  if (filters?.productCategories?.length > 0)
    mappings.category_ids = filters.productCategories
  params = createQueryParamUrl(mappings).join("&")
  url = sourceUrl + `${params ? "?" + params : params}`
  return url
}

const setCommodityFilterMappings = (data, dispatch) => {
  let commodityCategoryMap = {}
  data.forEach((item) => {
    commodityCategoryMap[item.category.id] = item.category.name
  })
  dispatch({
    type: Actions.SET_COMMODITY_FILTER_MAPPINGS,
    data: { commodityCategoryMap },
  })
}

const handleLogout = () => {
  keycloak.logout()
  LocalStorage.destroy.all()
  clearCommodities()
  clearUserDetails()
  window.location.href = "/"
}
