import React, { Component } from "react";
import Chart from "react-apexcharts";

//component
import SelectBox from "../../formFields/SelectBox";

//icons
import { FaSortDown } from "react-icons/fa";


export default class Price extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statement: [
        {
          id: 0,
          title: 'Last 1 Month',
          listInfo: '',
          selected: false,
          key: 'statement'
        },
        {
          id: 1,
          title: 'Last 3 months',
          listInfo: '',
          selected: false,
          key: 'statement'
        },
        {
          id: 2,
          title: 'Last 6 months',
          listInfo: '',
          selected: false,
          key: 'statement'
        },
        {
          id: 3,
          title: 'Last 1 Year',
          listInfo: '',
          selected: false,
          key: 'statement'
        },
      ],

      series: [{
        name: 'Mandi',
        data: [200, 110, 330, 180, 290, 480, 300, 220],
      }, 
      {
        name: 'Our Warehouse',
        data: [100, 150, 90, 230, 400, 206, 680, 430],
      }],

      options: {
        chart: {
          height: 235,
          type: 'area',
        },

        dataLabels: {
          enabled: false,
        },

        stroke: {
          curve: 'smooth',
        },

        colors: ['#ffb488', '#50b19f' ],

        xaxis: {
          type: 'month',
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
        },

        tooltip: {
          x: {
            format: 'MM',
            background: '#000000',
          },
        },
      },
    };
  }

  render() {
    return (
      <div className="app price-graph">
        <div className="row">
          <div className="mixed-chart">
            <div className="desc-section">
              <div className="values">
                <p className="price">Price</p>
                <SelectBox
                  title="Last 6 Months"
                  list={this.state.statement}
                />
              </div>
              <p className="percent-value"><span className="down-icon"><FaSortDown /></span>12% <span className="slash">|</span><span className="rates">Rates dropped by 20% over past 6 months</span></p>
            </div>

            <Chart
              options={this.state.options} 
              series={this.state.series} 
              type="area" 
              height={235}
            />
          </div>
        </div>
      </div>
    );
  }
}
