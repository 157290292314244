import React , {Component} from 'react';
import { Link, withRouter } from 'react-router-dom';
import {connect} from "react-redux";

//Components
import Header from '../Header';

//utils
import {BASE64_PREFIX} from "../../utilities/common/constants";

//Images
import menu from '../../assets/Images/menu.svg';
import whitemenu from '../../assets/Images/white-menu.svg';

//styles
import './styles.scss';


class MobileHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      header: false,
    };
    this.handleHeader = this.handleHeader.bind(this);
    this.closeHeader = this.closeHeader.bind(this);
  }

  handleHeader() {
    this.setState({ header: !this.state.header })
  }

  closeHeader() {
    this.setState({ header: false })
  }

  render() {
    const { header } = this.state
    const {userDetail} = this.props;

    return ( 
      <div className="mobile-header">
      <Link to="#" onClick={this.handleHeader} className="layout-grid black">
        <img src={menu} alt="=menu" />
      </Link>

      <Link to="#" onClick={this.handleHeader} className="layout-grid white">
        <img src={whitemenu} alt="=menu" />
      </Link>

      {header ?
        <div>
          <div className="backdrops"></div>
          <Header onClick={this.closeHeader} />
        </div>
        : (null)}

      <Link to="#" className="layout-grid main">
      {userDetail?.company?.image 
      ? 
      <img className="company-logo" src={BASE64_PREFIX + userDetail.company.image}
            alt="company-logo"/>
      : <></>
      }
      </Link>
    </div>
     );
  }
}


export default withRouter(connect(({user}) => ({...user}))(MobileHeader))
