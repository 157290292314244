import * as Actions from '../actions/actionTypes'

const initialState = {
    userDetail: {},
};

export const user = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SET_USER_DETAIL:
            return {
                ...state,
                userDetail: {
                    ...state.userDetail,
                    ...action.data
                }
            };
        case Actions.CLEAR_USER:
            return {}
        default:
            return state;
    }
};
