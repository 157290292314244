import * as Actions from '../actions/actionTypes'
import LocalStorage from "../../localStorage";

let initialState = {
    filters: {
        productCategories: []
    },
    list: [],
    requestedCommodities: LocalStorage.fetch.selectedCommodities()
                            ? JSON.parse(LocalStorage.fetch.selectedCommodities()) 
                            : []
};

export const commodity = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SET_MASTER_COMMODITY_LIST:
            return {
                ...state,
                masterData: action.data
            }
        case Actions.SET_COMMODITY_LIST:
            return {
                ...state,
                list: action.data
            }
        case Actions.SET_COMMODITY_FILTERS:
        case Actions.SET_COMMODITY_FILTER_MAPPINGS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.data
                }
            }
        case Actions.SET_REQUESTED_COMMODITIES:
            return {
                ...state,
                requestedCommodities: action.data
            }
        case Actions.CLEAR_COMMODITY:
            return {}
        default:
            return state;
    }
};
