import React, { Component } from "react"

//components
import PrimaryButton from "../../components/buttons/PrimaryButton"

//images
import internet from "../../assets/Images/internet.png"

//styles
import "./styles.scss"

export default class NoInternetConnection extends Component {
  render() {
    return (
      <div className="internet-failed">
        <img className="internet-image" src={internet} alt="empty-state" />
        <p className="message">User Not Found!!</p>
        <p className="info">
          Sorry we could not find you in the system. Please drop an email to&nbsp;
          <a href="mailto:manish.yadav@agrevolution.in">
            manish.yadav@agrevolution.in
          </a>
          &nbsp;with your details to get yourself onboarded into our system. We look
          forward to welcoming you into DeHaat's digital services.
        </p>
        <PrimaryButton btnText="Try Again" />
      </div>
    )
  }
}
