import LocalStorage from "../../localStorage"
import * as Actions from "./actionTypes"
import { URL } from "../../network"
import { get } from "../../network"

const handleError = (err) => {
  console.log("Error in user action : ", err)
  return err.response || err
}

export const getUserInfo = (payload) => async (dispatch) => {
  try {
    const response = await get(URL.login.userInfo, payload)
    if (response?.error === null) {
      let { data } = response
      LocalStorage.set.aerosAuthToken(data.aeros_access_token)
      dispatch({
        type: Actions.SET_USER_DETAIL,
        data: data,
      })
      return response
    }
    return response
  } catch (err) {
    console.log("Error in getting user info", err.response)
    LocalStorage.destroy.all()
    dispatch({
      type: Actions.CLEAR_USER,
    })
    return handleError(err)
  }
}

export const getDeliveryAddress = (payload) => async () => {
  try {
    const response = await get(URL.address.list, payload)
    return response
  } catch (err) {
    return handleError(err)
  }
}

export const clearUserDetails = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_USER,
    data: {},
  })
}
