import React , {Component} from 'react';
import { Link, withRouter } from "react-router-dom";

//images
import backIcon from '../../../assets/Images/back-icon.svg';

//styles
import './styles.scss';


class BackButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  goBack = (e) => {
    e.preventDefault()
    this.props.history.goBack();
  }
  
  render() {
    return (
      <div className="back-button">
        <Link to="#" onClick={this.goBack} className="back-btn">
          <span className="icon-left"><img src={backIcon} alt="back" /></span>
          Back
        </Link>
      </div>
    )
  }
}

export default withRouter(BackButton)
