import { get, URL } from "../../network"
import { createQueryParamUrl, changeDateFormat } from "../../helpers"
import LocalStorage from "../../localStorage"
import { USER_DISABLE_ERROR } from "../../common/constants"

//actions
import * as Actions from "./actionTypes"
import { clearUserDetails } from "./user"
import { clearCommodities } from "./commodity"
import keycloak from "../../keycloak/config"

const handleError = (err) => {
  console.log("Error in ledger action : ", err)
  return err.response || err
}

export const getLedgerList = (filters) => async (dispatch) => {
  try {
    let url = generateFilterUrl(URL.ledgers.list, filters)
    const response = await get(url)
    if (response?.error === null) {
      let { data } = response
      dispatch({
        type: Actions.SET_LEDGER_LIST,
        data: data || [],
      })
      return response
    } else if (response?.error === USER_DISABLE_ERROR) {
      handleLogout()
      return null
    }
  } catch (err) {
    return handleError(err)
  }
}

export const getLedgerAttachement = (entry_id) => async (dispatch) => {
  try {
    const response = await get(URL.ledgers.attachement, { entry_id })
    if (response?.error === null) {
      let { data } = response
      return data
    }
  } catch (err) {
    return handleError(err)
  }
}

export const clearLedgers = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_LEDGERS,
    data: {},
  })
}
export const updateLedgerFilters = (filters) => async (dispatch) => {
  dispatch({
    type: Actions.SET_LEDGER_FILTERS,
    data: filters,
  })
}

const generateFilterUrl = (sourceUrl, filters) => {
  let mappings = {},
    params = [],
    url = ""
  if (filters?.limit) mappings.limit = filters.limit
  if (filters.offset || filters.offset === 0) mappings.offset = filters.offset
  if (filters.documentType) mappings.req_journal_type = filters.documentType
  if (filters.fromDate) {
    let today = new Date()
    mappings.to_date = changeDateFormat(today)
    mappings.from_date = changeDateFormat(new Date(filters.fromDate))
  }
  params = createQueryParamUrl(mappings).join("&")
  url = sourceUrl + "?" + params
  return url
}

const handleLogout = () => {
  keycloak.logout()
  LocalStorage.destroy.all()
  clearCommodities()
  clearUserDetails()
  window.location.href = "/"
}
