import React, { Component } from 'react';

//images
import spinner from '../../assets/Images/spinner.gif';

//styles
import './styles.scss';


export default class Loader extends Component {
  render() {
    return (
      <div className="loader-state">
        <div className="loading-container">
          <img className="loader-image" src={spinner} alt="loader-state" />
          <p className="message mb-0">
            Loading...
          </p>
        </div>
      </div>
    );
  }
}
