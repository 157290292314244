import React, { Component } from "react";
import Chart from "react-apexcharts";

//component
import SelectBox from "../../formFields/SelectBox";

//icons
import { FaSortDown } from "react-icons/fa";


export default class Yield extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statement: [
        {
          id: 0,
          title: 'Last 1 Month',
          listInfo: '',
          selected: false,
          key: 'statement'
        },
        {
          id: 1,
          title: 'Last 3 months',
          listInfo: '',
          selected: false,
          key: 'statement'
        },
        {
          id: 2,
          title: 'Last 6 months',
          listInfo: '',
          selected: false,
          key: 'statement'
        },
        {
          id: 3,
          title: 'Last 1 Year',
          listInfo: '',
          selected: false,
          key: 'statement'
        },
      ],

      series: [{
        name: 'Yields',
        data: [44, 55, 41, 67, 22, 43, 21, 33, 45, 31, 87, 65, 35]
      }],

      options: {
        annotations: {
          points: [{
            x: 'Yields',
            seriesIndex: 0,
            label: {
              borderColor: '#775DD0',
              offsetY: 0,
              style: {
                color: '#fff',
                background: '#775DD0',
              },
              text: 'Yields are good',
            }
          }]
        },
        chart: {
          height: 235,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
            endingShape: 'rounded'  
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2
        },
        
        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },

        colors: ['#d4e189', '#d4e189'],

        xaxis: {
          type: 'month',
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
        },

        yaxis: {
          title: {
            text: 'Yields',
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100]
          },
        }
      },
    };
  }

  render() {
    return (
      <div className="app yield-graph">
        <div className="row">
          <div className="mixed-chart">
            <div className="desc-section">
              <div className="values">
                <p className="price">Yield</p>
                <SelectBox
                  title="Last 6 Months"
                  list={this.state.statement}
                />
              </div>
              <p className="percent-value"><span className="down-icon"><FaSortDown /></span>23% <span className="slash">|</span><span className="rates">Rates dropped by 23% over past 6 months</span></p>
            </div>

            <Chart
              options={this.state.options} 
              series={this.state.series} 
              type="bar" 
              height={235}
            />
          </div>
        </div>
      </div>
    );
  }
}
