import LocalStorage from "../../localStorage"
import isEmpty from 'lodash/isEmpty'
import * as Actions from './actionTypes'
import axios from "axios"
import { URL } from '../../network'

const handleError = err => {
    console.log("Error in Login action : ", err);
    return err.response || err;
}

export const requestOtp = payload => async dispatch => {
    try {
        const response = await axios.post(process.env.REACT_APP_AUTH_BASE_URL + URL.login.requestOtp, payload)
        if (response && (response.status === 200 || response.status === 201)) {
            dispatch({
                type: Actions.SET_USER_DETAIL,
                data: {
                    contact: payload.mobile_number
                }
            });
        }
        return response
    } catch (err) {
        return handleError(err);
    }
}

export const verifyOtp = payload => async dispatch => {
    try {
        const response = await axios.post(process.env.REACT_APP_AUTH_BASE_URL + URL.login.verifyOtp, payload)
        if ((response?.status === 200 || response?.status === 201) && !isEmpty(response.data)) {
            let {data} = response.data;
            LocalStorage.set.authToken(data?.access_token.token || '')
            dispatch({
                type: Actions.SET_USER_AUTH_TOKEN,
                data: data?.access_token.token
            });
            return response
        }
    } catch (err) {
        return handleError(err);
    }
}


export const clearAuthToken = () => async dispatch => {
    dispatch({
        type: Actions.CLEAR_AUTH_TOKEN,
        data: {}
    })
}
