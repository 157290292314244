import React, {Component} from 'react';

//image
import downArrow from '../../../assets/Images/down-arrow.svg';

//styles
import './styles.scss';

export default class InputNumber extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quantity: 0,
            show: true,
            min: 0,
            max: 9999999999
        };
    }
    
    incrementItem = () => {
        this.setState(prevState => {
            if (prevState.quantity < 9999999999) {
                return {
                    quantity: prevState.quantity*1 + 1
                }
            } else {
                return null;
            }
        }, () => this.props.handleChange(this.state.quantity));
    }
    
    decreaseItem = () => {
        this.setState(prevState => {
            if (prevState.quantity > 0) {
                return {
                    quantity: prevState.quantity - 1
                }
            } else {
                return null;
            }
        }, () => this.props.handleChange(this.state.quantity));
    }
    
    handleChange = (event) => {
        if (event.target.value >= 0)
        this.setState({quantity: event.target.value}, () => this.props.handleChange(this.state.quantity));
    }
    
    maxLengthCheck = (event) => {
        if (event.target.value.length > event.target.maxLength) {
            event.target.value = event.target.value.slice(0, event.target.maxLength)
        }
    }
    
    render() {
        const {placeholder, rupeeIcon} = this.props
        const {min, max, quantity} = this.state
        return (
            <div className="input-number-primary">
            <input className="number-input" type="number" min={min} maxLength = "10" max={max} onInput={this.maxLengthCheck} placeholder={placeholder} value={quantity}
            onChange={this.handleChange} />
            <span className="inc-dec">
            <span className="icon-inc-dec" onClick={this.incrementItem}>
            <img src={downArrow} alt="down-arrow" className="dropdown-icons up-arrow"/>
            </span>
            <span className="icon-inc-dec decrease" onClick={this.decreaseItem}>
            <img src={downArrow} alt="down-arrow" className="dropdown-icons"/>
            </span>
            </span>
            {rupeeIcon && (<span className="rupees-icon amount-value">₹</span>)}
            </div>
            );
        }
    }
    