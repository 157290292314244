import React , {Component} from 'react';

//styles
import './styles.scss';

export default class Textarea extends Component {

    constructor(props) {
        super(props)
        this.state = {value: ''};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({value: event.target.value},()=>this.props.handleChange(this.state.value));
    }

    render() {
        const { value } = this.state
        const { placeholder } = this.props

        return ( 
            <div className="textarea-message">
                <textarea 
                    className="textarea" 
                    placeholder={placeholder}
                    value={value}
                    onChange={this.handleChange}>
                </textarea>
            </div>
        );
    }
}
