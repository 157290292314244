import React , {Component} from 'react';
import { Row, Col, Tab, Nav } from 'react-bootstrap';

//component
import TabsContent from '../TabsContent';

//styles
import './styles.scss';


export default class HelpAccordian extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return ( 
      <div className="help-accordian">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col xs={12} sm={12} md={4} lg={3} xl={3}>
              <Nav variant="pills" className="tabs-content-section">
                <Nav.Item>
                  <Nav.Link eventKey="first">Account</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="second">Payments</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="third">Commodity Request</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="fourth">Service Request</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="fifth">Warehouse Storage</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="sixth">Financial Assistance</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>

            <Col xs={12} sm={12} md={8} lg={9} xl={9}>
              <div className="tabs-section">
              <h3 className="main-heading">Frequestly asked queries related to Account</h3>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <TabsContent />
                </Tab.Pane>

                <Tab.Pane eventKey="second">
                  <TabsContent />
                </Tab.Pane>

                <Tab.Pane eventKey="third">
                  <TabsContent />
                </Tab.Pane>

                <Tab.Pane eventKey="fourth">
                  <TabsContent />
                </Tab.Pane>

                <Tab.Pane eventKey="fifth">
                  <TabsContent />
                </Tab.Pane>

                <Tab.Pane eventKey="sixth">
                  <TabsContent />
                </Tab.Pane>
              </Tab.Content>
              </div>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    );
  }
}
