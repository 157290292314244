import React , {Component} from 'react';
import { withRouter } from 'react-router-dom';

//components
import CommodityCatelogueSelection from '../../components/CommodityCatelogueSelection';


class CommodityCatelogue extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="commodity-catelogue">
        <CommodityCatelogueSelection 
          heading="Pick the commodities you are interested in"
          subheading="This will help us personalize your experience at Dehaat"
          description="Showing Fresh Commodities"
          handleClick={() => this.props.history.push('/home-page')}
        />
      </div>
    )
  }
}

export default withRouter(CommodityCatelogue)
