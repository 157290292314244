import React, { Component } from "react"
import { Link } from "react-router-dom"

//component
import ConfirmationModal from "../../ConfirmationModal"
import InputNumber from "../../formFields/InputNumber"
import TextArea from "../../formFields/Textarea"

//images
import deleteIcon from "../../../assets/Images/delete-icon.svg"
import trashIcon from "../../../assets/Images/trash.svg"

//styles
import "./styles.scss"

export default class OrderDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmationBoxActive: false,
      selectedVariant: null,
      quantity: 0,
      price: 0,
      quality: "",
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.quantity !== this.state.quantity || prevState.price !== this.state.price)
      this.updateOrderCost(
        this.state.quantity,
        this.state.selectedVariant,
        this.state.quality,
        this.state.price
      )
  }

  confirmationBoxShow = () => {
    this.setState({
      confirmationBoxActive: true,
    })
  }

  confirmationBoxHide = () => {
    this.setState({
      confirmationBoxActive: false,
    })
  }

  updateOrderCost = (quantity, selectedVariant, quality, price) => {
    if (quantity != null && price != null)
      this.props.updateOrderSummary(this.props.id, {
        selectedVariant,
        cost: price * quantity,
        quantity,
        quality,
      })
  }

  handleChange = (value) => {
    this.setState(
      { quality: value },
      this.updateOrderCost(
        this.state.quantity,
        this.state.selectedVariant,
        this.state.quality,
        this.state.price
      )
    )
  }

  trackRemoveCommodityEvent() {
    if(typeof window !== 'undefined' && window.gtag != null) {
        window.gtag('set', 'event', { action: 'cart_commodity_removed'})
    }
}

  render() {
    const { confirmationBoxActive } = this.state
    const {
      name,
      id,
      src,
      description,
      uom,
      quantity,
      errors,
      price,
    } = this.props
    console.log(JSON.stringify(errors))
    return (
      <div key={id} className="order-details">
        <div className="info-section">
          <img className="item-image" src={src} alt="item" />
          <div className="content">
            <p className="name">{name}</p>
            <p className="desc">{description}</p>
          </div>
          <Link
            to="#"
            onClick={this.confirmationBoxShow}
            className="remove-icon"
          >
            <img className="delete-icon" src={deleteIcon} alt="remove-icon" />
            Remove
          </Link>
        </div>

        <div className="region">
          <div className="regions flex-box">
            <div className="variety">
              <p className="select-region">Price</p>
              <div className="quantity-weight">
                <InputNumber
                  value={price}
                  handleChange={(price) => this.setState({ price })}
                  placeholder="Enter Price"
                />
              </div>
              {errors?.price?.length > 0 && (
                <p className="error">Product price should not less than 1</p>
              )}
            </div>

            <div className="quantity">
              <p className="select-region">Quantity</p>
              <div className="quantity-weight">
                <InputNumber
                  value={quantity}
                  handleChange={(quantity) => this.setState({ quantity })}
                  placeholder="Enter Quantity"
                />
                <span className="kg">{uom?.name}</span>
              </div>
              {errors?.quantity?.length > 0 && (
                <p className="error">Product quantity should not less than 1</p>
              )}
            </div>
          </div>

          <div className="quality-request">
            <p className="select-region">Quality</p>
            <TextArea
              className="quality"
              placeholder="Describe quality request.."
              handleChange={this.handleChange}
            />
          </div>
        </div>

        {confirmationBoxActive ? (
          <ConfirmationModal
            handleClick={() => {
              this.trackRemoveCommodityEvent()
              this.props.removeOrderItem(id)
              this.confirmationBoxHide()
            }}
            show={this.state.confirmationBoxActive}
            onHide={this.confirmationBoxHide}
            src={trashIcon}
            message={`Are you sure you want to remove ${name}?`}
            headerActive={true}
            primaryBtnText="Yes"
            OutlineButtonActive={true}
            handleClickCancel={this.confirmationBoxHide}
          />
        ) : null}
      </div>
    )
  }
}

