import React , {Component} from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';

//icons
import { FaMinus, FaPlus } from "react-icons/fa";

//styles
import './styles.scss';


export default class TabsContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabOpen: false,
      activeCollapse: 0,
    };
  }

  toggleList = (value) => {
    this.setState({activeCollapse: value})
  }

  render() {
    const { activeCollapse } = this.state;

    return ( 
      <div className="tabs-content">
        <Accordion defaultActiveKey="0" onSelect={() => this.toggleList(null)}>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0" 
                onClick={() => 
                {
                  if (activeCollapse === 0 ) {
                    this.toggleList(null)
                }
                else {
                  this.toggleList(0)
                }
              }}
              >
                <div className="d-flex justify-space">
                  <h5 className="question">Why haven’t we received our request yet?</h5>
                  <div className="collapse show">
                    {activeCollapse === 0 ? (
                      <FaMinus className="icons" />
                    ) : (
                      <FaPlus className="icons" />
                    )}
                  </div>
                </div>
              </Accordion.Toggle>
            </Card.Header>

            <Accordion.Collapse eventKey="0">
              <Card.Body>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit amet malesuada, purus eget laoreet accumsan, augue turpis pharetra ante, vel feugiat dui lectus non odio.</Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="1" 
                onClick={() => 
                  {
                  if (activeCollapse === 1 ) {
                    this.toggleList(null)
                  }
                  else {
                    this.toggleList(1)
                  }
                }}
              >
                <div className="d-flex justify-space">
                  <h5 className="question">How long does it typically take for a request to be completed?</h5>
                  <div className="collapse show">
                    {activeCollapse === 1 ? (
                      <FaMinus className="icons" />
                    ) : (
                      <FaPlus className="icons" />
                    )}
                  </div>
                </div>
              </Accordion.Toggle>
            </Card.Header>

            <Accordion.Collapse eventKey="1">
              <Card.Body>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit amet malesuada, purus eget laoreet accumsan, augue turpis pharetra ante, vel feugiat dui lectus non odio.</Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="2" 
                onClick={() => 
                  {
                  if (activeCollapse === 2 ) {
                    this.toggleList(null)
                  }
                  else {
                    this.toggleList(2)
                  }
                }}
              >
                <div className="d-flex justify-space">
                  <h5 className="question">Can i change the commodity requested? how?</h5>
                  <div className="collapse show">
                    {activeCollapse === 2 ? (
                      <FaMinus className="icons" />
                    ) : (
                      <FaPlus className="icons" />
                    )}
                  </div>
                </div>
              </Accordion.Toggle>
            </Card.Header>

            <Accordion.Collapse eventKey="2">
              <Card.Body>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit amet malesuada, purus eget laoreet accumsan, augue turpis pharetra ante, vel feugiat dui lectus non odio.</Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="3"
                onClick={() => 
                  {
                  if (activeCollapse === 3) {
                    this.toggleList(null)
                  }
                  else {
                    this.toggleList(3)
                  }
                }}
              >
                <div className="d-flex justify-space">
                  <h5 className="question">Is it possible to cancel the commodity request?</h5>
                  <div className="collapse show">
                    {activeCollapse === 3 ? (
                      <FaMinus className="icons" />
                    ) : (
                      <FaPlus className="icons" />
                    )}
                  </div>
                </div>
              </Accordion.Toggle>
            </Card.Header>

            <Accordion.Collapse eventKey="3">
              <Card.Body>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit amet malesuada, purus eget laoreet accumsan, augue turpis pharetra ante, vel feugiat dui lectus non odio.</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    );
  }
}
