import React, {Component} from 'react';

//styles
import './styles.scss';

export default class DatePicker extends Component {

    handleChange = (event) => {
        this.props.updateDate(event.target.value);
    }

    render() {
        const {placeholder} = this.props
        return (
            <div className="input-date-picker">
                <p className="status">{placeholder}</p>
                <input className="date-input" type="date" name="End Date" onChange={this.handleChange}/>
            </div>
            );
    }
}
