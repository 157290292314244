import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"

//utils
import { BASE64_PREFIX } from "../../utilities/common/constants"
import LocalStorage from "../../utilities/localStorage"
import { clearUserDetails } from "../../utilities/Store/actions/user"
import { clearCommodities } from "../../utilities/Store/actions/commodity"
import { clearAuthToken } from "../../utilities/Store/actions/login"
import keycloak from "../../utilities/keycloak/config"

//images
import companyMail from "../../assets/Images/company-mail.svg"
import phone from "../../assets/Images/call.svg"
import address from "../../assets/Images/address.svg"
import gst from "../../assets/Images/gst.svg"
import logoutIcon from "../../assets/Images/logout-icon.svg"

//styles
import "./styles.scss"

class ProfileDrawer extends Component {
  handleLogout = () => {
    LocalStorage.destroy.all()
    this.props.clearUserDetails()
    this.props.clearCommodities()
    keycloak.logout()
  }

  render() {
    let { userDetail } = this.props
    return (
      <div className="profile-section">
        <div className="backdrops" onClick={this.props.profileActiveShow}></div>
        <div className="profile-drawer">
          <div className="company-details">
            {userDetail?.company?.image ? (
              <img
                className="company-logo"
                src={BASE64_PREFIX + userDetail.company.image}
                alt="company-logo"
              />
            ) : (
              <></>
            )}
            <p className="company-name">{userDetail?.company?.name || "-"}</p>
          </div>

          <div className="personal-details">
            <div className="container-box">
              <p className="label">
                <span className="label-icon">
                  <img src={phone} alt="phone" />
                </span>
                Phone number
              </p>
              <p className="info">+91 {userDetail?.mobile}</p>
            </div>

            <div className="container-box">
              <p className="label">
                <span className="label-icon">
                  <img src={companyMail} alt="companyMail" />
                </span>
                Email
              </p>
              <p className="info">{userDetail?.email || "-"}</p>
            </div>

            <div className="container-box">
              <p className="label">
                <span className="label-icon">
                  <img src={gst} alt="gst" />
                </span>
                GSTIN
              </p>
              <p className="info">{userDetail?.company?.gstin || "-"}</p>
            </div>

            <div className="container-box">
              <p className="label">
                <span className="label-icon">
                  <img src={address} alt="address" />
                </span>
                Address
              </p>
              <p className="special-info">
                {`${userDetail?.company?.address} - ${userDetail?.company?.zip}` ||
                  "-"}
              </p>
            </div>
          </div>

          <div className="sign-out-container">
            <Link
              to="#"
              className="sign-out"
              onClick={() => this.handleLogout()}
            >
              <span className="logout-icon">
                <img src={logoutIcon} alt="logout-icon" />
              </span>
              Sign out
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(
  connect(({ user }) => ({ ...user }), {
    clearUserDetails,
    clearCommodities,
    clearAuthToken,
  })(ProfileDrawer)
)
