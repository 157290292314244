import React, { Component } from "react";
import { Link } from "react-router-dom";

//components
import DownloadIcon from "../../DownloadIcon";

//styles
import "./styles.scss";
import moment from "moment";

//utils
import { getFormattedPrice } from "../../../utilities/helpers";

//images
import spinner from "../../../assets/Images/spinner.gif";

export default class InvoiceListing extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      payment: false,
    };
    this.detailsShow = this.detailsShow.bind(this);
    this.detailsHide = this.detailsHide.bind(this);
    this.paymentShow = this.paymentShow.bind(this);
  }

  detailsShow() {
    this.setState({ show: true });
  }

  detailsHide() {
    this.setState({ show: false });
  }

  paymentShow() {
    this.setState((prevState) => ({
      payment: !prevState.payment,
    }));
  }

  render() {
    const { show } = this.state;
    const {
      status,
      abs_balance,
      iconName,
      iconImg,
      date,
      handleClick,
      invoice_id,
      isDownloading,
    } = this.props;
    return (
      <div
        className="invoice-listing"
        onMouseOver={this.detailsShow}
        onMouseLeave={this.detailsHide}
      >
        <Link to="#" className="invoice-sections">
          <div className="payment-listings invoice-serial-no">
            <img src={iconImg} alt="invoice-icon" className="icon-size" />
            <p className="icon-name">{iconName}</p>
          </div>

          <div className="payment-listings invoice-Date">
            <p className="date">{moment(date)?.format("DD/MM/YYYY") || ""}</p>
            <p className="icon-name">Issued on</p>
          </div>

          <div className="payment-listings invoice-amount">
            <p className="amount">
              <span className="rupees-icon">₹</span>
              {getFormattedPrice(abs_balance)}
            </p>
          </div>

          <div className="payment-listings invoice-status">
            <button
              className={`outlines ${
                status === "Outstanding"
                  ? "red"
                  : status === "Settled" || status === null
                  ? "green"
                  : "orange"
              }`}
            >
              {status == null ? "Completed" : status}
            </button>
          </div>
        </Link>

        <div className="payment-listings invoice-paynow">
          <div>
            {show && invoice_id !== null ? (
              <div className="d-flex">
                <span className="download-details" onClick={handleClick}>
                  {isDownloading ? (
                    <img
                      className="loader-image"
                      src={spinner}
                      alt="loader-state"
                    />
                  ) : (
                    <DownloadIcon />
                  )}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
