import { axiosInstance as axios } from "./config";

const get = (url, params) => axios.get(url, { params });

const remove = (url, params) => axios.delete(url, { params });

const post = (url, body) => axios.post(url, body);

const put = (url, body) => axios.put(url, body);

const patch = (url, body) => axios.patch(url, body);

export { get, remove, post, put, patch };
