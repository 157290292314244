import React, { Component } from "react"
import { withRouter } from "react-router-dom"

//components
import OutlineButton from "../../buttons/OutlineButton"

//styles
import "./styles.scss"

//utils
import { get, URL } from "../../../utilities/network"

class InsightItem extends Component {
  trackDownloadFileEvent() {
    const { report_name } = this.props.insightDetail
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "file_download", { file_name: report_name })
    }
  }

  async downloadFile(name) {
    try {
      const response = await get(URL.insights.download + name)
      if (response.data) {
        this.trackDownloadFileEvent()
        window.open(response.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    const { report_name, image } = this.props.insightDetail

    return (
      <div className="insight-listings-table">
        <div className="insight-list-name">
          <div className="insight-name listings">
            <p className="date">{report_name}</p>
          </div>
          <div className="insight-image listings">
            {image && (
              <img className="insight-img" src={image} alt="insight-list" />
            )}
          </div>
          <div className="insight-download listings">
            <OutlineButton
              btnText="Download"
              handleClick={() => this.downloadFile(report_name)}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(InsightItem)
