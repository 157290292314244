import React , {Component} from 'react';
import { Link } from 'react-router-dom';

//Images
import downloadIcon from '../../assets/Images/download-icon.svg';


export default class DownloadIcon extends Component {

  render() {
    return ( 
      <div className="download-icon">
        <Link to="#" className="icon download">
          <img className="icon-img-download" src={downloadIcon} alt="download" />
        </Link>
      </div>
     );
  }
}
 