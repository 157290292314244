import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

//components
import CheckboxLabel from "../../components/Checkbox";
import Loader from '../../components/Loader';

//icons
import {FaAngleDown, FaAngleUp} from "react-icons/fa";

//images
import cancelIcon from '../../assets/Images/cancel-gray-icon.svg';
import clearIcon from '../../assets/Images/clear-icon.svg';
import {renderPerishableIcon} from "../../assets/Images/svgComponents";

//actions
import * as commodityActions from "../../utilities/Store/actions/commodity";

//styles
import './styles.scss';

const PARENT_CATEGORY = ['Perishable', 'Non-Perishable']

class SidebarNavigation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openLists: {},
            commodityCategory: {},
            selectedCategories: this.props.commodityFilters?.productCategories || [],
            parentCategoryCounts: {},
        }
    }

    async componentDidMount() {
        let {commodityCategory} = this.state;
        this.props.commodityMasterData?.length > 0 && this.props.commodityMasterData.forEach(item => {
            commodityCategory[item?.parent_category?.name] = {
                ...commodityCategory[item?.parent_category?.name],
                [item.category?.name]: (
                    commodityCategory[item?.parent_category?.name]?.[item.category?.name]?.length > 0 ?
                        [...commodityCategory[item?.parent_category?.name]?.[item.category?.name], item]
                        : [item]
                )
            }
        })
        let parentCategoryCounts = getLeafCount(commodityCategory)
        this.setState({commodityCategory, parentCategoryCounts})
    }

    toggleList(value) {
        let {openLists} = this.state;
        if (value) {
            openLists[value] = !openLists[value]
        }
        this.setState({
            openLists
        })
    }

    onCategorySelect = (parentCategory, subCategory) => {
        let categoryId = parentCategory?.[subCategory]?.[0]?.category?.id
        let {selectedCategories} = this.state;
        if (selectedCategories.includes(categoryId))
            selectedCategories.splice(selectedCategories.indexOf(categoryId), 1)
        else
            selectedCategories = [...selectedCategories, categoryId]
        this.setState({selectedCategories})
        this.props.handleFilters({productCategories: selectedCategories}, true)
    }

    render() {
        const {onClick, clearWebIcon} = this.props;
        let {commodityCategory, parentCategoryCounts} = this.state

        return (
            <div onClick={(e) => {
                e.stopPropagation()
            }} className="main-sidebar mobile">
                <div className="choose-categories">
                    <h4 className="main-heading">Choose Categories</h4>
                    <Link to="#" onClick={onClick} className="gray-cancel-item">
                        <img src={cancelIcon} alt="cancel-icon"/>
                    </Link>
                    {clearWebIcon && (
                        <Link to="#" onClick={() => {
                            this.setState({
                                selectedCategories: []
                            })
                            this.props.handleFilters({productCategories: []}, true)
                        }}
                              className="red-cancel-item">
                            <img className="cancel-icon" src={clearIcon} alt="cancel-icon"/>
                            Clear all
                        </Link>)}
                </div>
                {this.state.loading && <Loader/>}
                {
                    PARENT_CATEGORY.map(item => {
                        return (
                            commodityCategory[item] && <div className="commodity-items-list">
                                <div className="items" onClick={() => this.toggleList(item)}
                                     style={this.state.openLists[item] ? {backgroundColor: '#a1cc37'} : null}>
                                    <div className="d-flex">
                                        <div className="icon">
                                            {renderPerishableIcon(item, this.state.openLists[item])}
                                        </div>

                                        <div className="info-section">
                                            <p className="sub-heading"
                                               style={this.state.openLists[item] ? {color: '#ffffff'} : null}>
                                                {item} Commodity
                                            </p>
                                            <p className="count"
                                               style={this.state.openLists[item] ? {color: '#ffffff'} : null}>
                                                {parentCategoryCounts?.[item]} items
                                            </p>
                                        </div>
                                    </div>

                                    <div className="dropdown-icon">
                                        {(this.state.openLists[item])
                                            ?
                                            <FaAngleDown
                                                style={this.state.openLists[item] ? {color: '#ffffff'} : null}/>
                                            : <FaAngleUp
                                                style={this.state.openLists[item] ? {color: '#ffffff'} : null}/>
                                        }
                                    </div>
                                </div>

                                {(this.state.openLists[item]) ? (
                                    <div>
                                        <ul className="list">
                                            {
                                                commodityCategory[item] && Object
                                                    .keys(commodityCategory[item])
                                                    .map((category, index) => {
                                                        return <li
                                                            onClick={() => this.onCategorySelect(commodityCategory[item], category)}
                                                            className="list-item">
                                                            <CheckboxLabel
                                                                name={category}
                                                                isChecked={this.props.commodityFilters?.productCategories?.includes(commodityCategory[item]?.[category]?.[0]?.category?.id)}
                                                            />
                                                            <p className="count-items">
                                                                {commodityCategory[item]?.[category]?.length} items
                                                            </p>
                                                        </li>
                                                    })
                                            }
                                        </ul>
                                    </div>
                                ) : null}
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}


function getLeafCount(data, obj = {}) {
    if (data)
        Object.keys(data)
            .forEach(key => {
                if (!Array.isArray(data[key]))
                    return getLeafCount(data[key], obj)

                else {
                    let parentCategory = data[key][0].parent_category.name
                    obj[parentCategory] = (obj[parentCategory] || 0) + data[key].length
                }
            })
    return obj
}

export default connect(
    ({commodity,}) => ({
        commodityList: commodity.list,
        commodityMasterData: commodity.masterData,
        commodityFilters: commodity.filters
    }),
    dispatch => bindActionCreators({...commodityActions}, dispatch)
)(SidebarNavigation)