import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs } from "react-google-maps";

// Image
import markerIcon from "../../assets/Images/grapes.png";

const {
  MarkerWithLabel
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");

const labelSize = { width: 291 };
const labelPadding = 8;
const defaultMapOptions = {
  fullscreenControl: false,
  draggable: true,
  zoomControl: true,
};

class MapPrimary extends Component {
  render() {
    return (
      <GoogleMap
        ref={(mapRef) => { this.ref = mapRef }}
        onCenterChanged={() => {
          if (this.ref) {
            let br = this.ref.getCenter()
            this.props.onCenterChanged(br.lat(), br.lng())
          }
        }}
        defaultZoom={this.props.zoom}
        defaultOptions={defaultMapOptions}
        defaultCenter={this.props.center}>
        {this.props.places.map((place, index) => {
          return (
            <MarkerWithLabel
              labelStyle={{
                textAlign: "center",
                width: labelSize.width + "px",
                backgroundColor: "#0F1938",
                fontSize: "13px",
                padding: labelPadding + "px",
                color: '#B7BAC3',
                borderRadius: '6px',
                lineHeight: '1.5'
              }}
              icon={{
                url: markerIcon,
              }}
              labelClass="map-label"
              labelAnchor={{ x: labelSize.width / 2 + labelPadding, y: 105 }}
              key={place.id}
              position={{ lat: place.lat, lng: place.lng }}

            >
              <span>{place.name}</span>
            </MarkerWithLabel>
          );
        })}
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(MapPrimary));