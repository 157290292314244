import { get, post, URL } from "../../network"
import * as Actions from "./actionTypes"
import { createQueryParamUrl } from "../../helpers"
import LocalStorage from "../../localStorage"
import { clearUserDetails } from "./user"
import { clearCommodities } from "./commodity"
import { USER_DISABLE_ERROR } from "../../common/constants"
import keycloak from "../../keycloak/config"

const handleError = (err) => {
  return Promise.reject(err.response || err)
}

export const getOrdersList = (filters) => async (dispatch) => {
  try {
    let url = generateFilterUrl(URL.orders.list, filters)
    const response = await get(url)
    if (response?.error === null && response?.data?.count > 0) {
      let { data } = response
      dispatch({
        type: Actions.SET_ORDERS_LIST,
        data: data,
      })
      return response
    } else if (response?.error === USER_DISABLE_ERROR) handleLogout()
    return null
  } catch (err) {
    return handleError(err)
  }
}

export const createOrder = (payload) => async () => {
  try {
    const response = await post(URL.orders.create, payload)
    if (response?.error === null) {
      return response
    } else if (response?.error === USER_DISABLE_ERROR) {
      handleLogout()
      return null
    }
  } catch (err) {
    return handleError(err)
  }
}

export const updateOrderFilters = (filters) => async (dispatch) => {
  dispatch({
    type: Actions.SET_ORDERS_FILTERS,
    data: filters,
  })
}

const generateFilterUrl = (sourceUrl, filters) => {
  let mappings = {},
    params = [],
    url = ""
  if (filters?.status) mappings.status = filters.status
  if (filters?.limit) mappings.limit = filters.limit
  if (filters.offset || filters.offset === 0) mappings.offset = filters.offset
  params = createQueryParamUrl(mappings).join("&")
  url = `${sourceUrl}?${params}`
  return url
}

const handleLogout = () => {
  keycloak.logout()
  LocalStorage.destroy.all()
  clearCommodities()
  clearUserDetails()
  window.location.href = "/"
}
