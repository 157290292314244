import * as Actions from "../actions/actionTypes"

let initialState = {
  filters: {
    limit: 10,
    offset: 0,
    types: undefined,
    states: undefined,
    seasons: undefined,
    crops: undefined
  },
  list: [],
  count: 0
}

export const insights = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_INSIGHTS_LIST:
      return {
        ...state,
        list: action.data,
      }
    case Actions.SET_INSIGHTS_COUNT:
      return {
        ...state,
        count: action.data,
      }
    case Actions.SET_INSIGHTS_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.data,
        },
      }
    default:
      return state
  }
}
