import React , {Component} from 'react';
import { Nav, Tab, Row, Col } from 'react-bootstrap';

//component
import Price from '../graphs/Price';
import CropStatus from '../graphs/CropStatus';
import Yield from '../graphs/Yield';
import Forcast from '../graphs/Forecast';

//images
import price from '../../assets/Images/price.svg';
import yeild from '../../assets/Images/yeild.svg';
import status from '../../assets/Images/status.svg';
import forecast from '../../assets/Images/forecast.svg';


//icon
import { MdLocationOn } from "react-icons/md";
import { FaCaretDown } from "react-icons/fa";

//styles
import './styles.scss';

export default class GoogleCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {    
    };
  }

  render() {    
    return ( 
      <div className="google-charts">
        <p 
          className="market">Market Intelligence 
          <span className="location"> From <span className="location-name"><MdLocationOn className="map-icon" />Ratnagiri, Maharashtra</span></span>
        </p>

        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <Nav variant="pills" className="flex-columns">
                <Nav.Item>
                  <Nav.Link eventKey="first">
                    <div className="information">
                      <div className="name">Price</div>
                      <div><FaCaretDown /><span className="percent">12%</span></div>
                    </div>
                    <span className="graph-imgs"><img src={price} alt="price" /></span>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="second">
                    <div className="information">
                      <div className="name">Crop Status</div>
                      <div><FaCaretDown /><span className="percent">98% Healthy</span></div>
                    </div>
                    <span className="graph-imgs"><img src={status} alt="status" /></span>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="third">
                    <div className="information">
                      <div className="name">Yield</div>
                      <div><FaCaretDown /><span className="percent">23%</span></div>
                    </div>
                    <span className="graph-imgs"><img src={yeild} alt="yield" /></span>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="fourth">
                    <div className="information">
                      <div className="name">Forecast</div>
                      <div><FaCaretDown /><span className="percent">30% Estimated</span></div>
                    </div>
                    <span className="graph-imgs"><img src={forecast} alt="forecast" /></span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>

            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Price />
                </Tab.Pane>

                <Tab.Pane eventKey="second">
                  <CropStatus />
                </Tab.Pane>

                <Tab.Pane eventKey="third">
                  <Yield />
                </Tab.Pane>

                <Tab.Pane eventKey="fourth">
                  <Forcast />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
     );
  }
}
