
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';

//component
import PrimaryButton from '../buttons/PrimaryButton';
import MapPrimary from '../MapPrimary';
import GoogleCharts from '../GoogleCharts';

//icons
import { FaMinus, FaPlus } from "react-icons/fa";

//images
import mango from '../../assets/Images/mango.png';
import scale from '../../assets/Images/scale.svg';
import fleshweight from '../../assets/Images/flesh-weight.svg';
import shape from '../../assets/Images/shape.svg';
import acidity from '../../assets/Images/acidity.svg';
import speed from '../../assets/Images/speed.svg';
import weight from '../../assets/Images/weight.svg';
import gradientOval from '../../assets/Images/gradient-oval.svg';

//styles
import './styles.scss';

class CommodityTabsContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 5,
      show: true,
      max: 100,
      min: 0,
      lat: 41.015137,
      lng: 28.979530,
    };
  }

  IncrementItem = () => {
    this.setState(prevState => {
      if (prevState.quantity < 100) {
        return {
          quantity: prevState.quantity + 1
        }
      } else {
        return null;
      }
    });
  }

  DecreaseItem = () => {
    this.setState(prevState => {
      if (prevState.quantity > 1) {
        return {
          quantity: prevState.quantity - 1
        }
      }
      else {
        this.setState({
          items: false,
        });
      }
    });
  }

  render() {
    const places = [
      {
        name: "135, 100 Feet Rd, Indiranagar II Stage, H Colony, Indiranagar, Bengaluru, Karnataka 560038",
        title: "Newcastle",
        lat: this.state.lat,
        lng: this.state.lng,
        id: 1
      },
    ];

    return (
      <div className="content-section">
        <Row>
          <Col xs={12} sm={12} md={12} lg={4} xl={3}>
            <div className="item-info">
              <div className="items-content-section">
                <div className="fruit-image">
                  <img className="item-image" src={mango} alt="items" />
                </div>

                <div className="desc">
                  <h4 className="fruit-name">Alhphonso Mangoes</h4>
                  <p className="fruit-info">
                    It has a very rich, creamy, tender & delicate,
                    juicy & non-fibrous pulp inside. Most of these species
                    are found in nature as wild mangoes.
                </p>
                </div>
              </div>

              <div className="quantity">
                <div>
                  <p className="starting-from">Starting from</p>
                  <p className="price">₹ 16.00 / kg</p>
                </div>

                <div>
                  <p className="starting-from">Quantity in
                    <span className="select-spanbox">
                      <select className="select-quantity">
                        <option>Quintal</option>
                        <option>Gram</option>
                        <option>Ton</option>
                        <option>KG</option>
                      </select>
                    </span>
                  </p>
                  <div className="price-section">
                    <Link to="#" onClick={this.DecreaseItem} className="minus"><FaMinus /></Link>
                    {this.state.show ? <p className="item-number">{this.state.quantity}</p> : ''}
                    <Link to="#" onClick={this.IncrementItem} className="plus"><FaPlus /></Link>
                  </div>
                </div>
              </div>

              <div className="request-btn">
                <PrimaryButton
                  btnText="Request"
                  handleClick={() => this.props.history.push('/service-request-form')}
                />
              </div>
            </div>
          </Col>

          <Col xs={12} sm={12} md={12} lg={8} xl={9}>
            <div className="location-map">
              <div className="quality-traits">
                <h4 className="heading">Quality Traits</h4>
                <div className="quality-traits-info">
                  <div className="quality-info">
                    <img className="quality-icon" src={scale} alt="quality-icon" />
                    <div>
                      <p className="grams">400-500g</p>
                      <p className="avg-weight">avg. weight</p>
                    </div>
                  </div>

                  <div className="quality-info">
                    <img className="quality-icon" src={fleshweight} alt="quality-icon" />
                    <div>
                      <p className="grams">85%</p>
                      <p className="avg-weight">Flesh weight</p>
                    </div>
                  </div>

                  <div className="quality-info">
                    <img className="quality-icon" src={shape} alt="quality-icon" />
                    <div>
                      <p className="grams">Oblong</p>
                      <p className="avg-weight">Shape of fruit</p>
                    </div>
                  </div>

                  <div className="quality-info">
                    <img className="quality-icon" src={speed} alt="quality-icon" />
                    <div>
                      <p className="grams">20:2</p>
                      <p className="avg-weight">Pulp:Seed Ratio</p>
                    </div>
                  </div>

                  <div className="quality-info">
                    <img className="quality-icon" src={acidity} alt="quality-icon" />
                    <div>
                      <p className="grams">0.22%</p>
                      <p className="avg-weight">Acidity</p>
                    </div>
                  </div>

                  <div className="quality-info">
                    <img className="quality-icon" src={weight} alt="quality-icon" />
                    <div>
                      <p className="grams">4.2%</p>
                      <p className="avg-weight">Seed weight</p>
                    </div>
                  </div>

                  <div className="quality-info">
                    <img className="oval" src={gradientOval} alt="oval" />
                    <div>
                      <p className="grams">Yellowish red</p>
                      <p className="avg-weight">Peel colour</p>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ height: 500 }}>
                <MapPrimary
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `100%` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  zoom={17}
                  places={places}
                  onCenterChanged={this.onCenterChanged}
                />
              </div>

              <GoogleCharts />
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default withRouter(CommodityTabsContent)
