import mapStyles from './mapConstants';

const PHONE_REGEX = /^[6-9][0-9]{9}$/;
const BASE64_PREFIX = 'data:image/png;base64,';
const USER_DISABLE_ERROR = 'Error Getting User Details\n User Not Found';

export {
    PHONE_REGEX,
    mapStyles,
    BASE64_PREFIX,
    USER_DISABLE_ERROR
}
