import React, { Component } from "react"

//icons
import { FaMinus, FaPlus } from "react-icons/fa"

//styles
import "./styles.scss"

export default class Accordian extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabOpen: false,
      body: this.props?.body || "",
    }
    this.handleChange = this.handleChange.bind(this)
  }

  toggleList() {
    this.setState((prevState) => ({
      tabOpen: !prevState.tabOpen,
    }))
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  handleChange(value) {
    this.setState({ body: value }, () =>
      this.props.handleChange(this.state.body)
    )
  }

  render() {
    const { tabOpen, body } = this.state
    const { question, questionInfo, src } = this.props

    return (
      <div className="accordian">
        <div className="tab-section" onClick={() => this.toggleList()}>
          <div className="d-flex">
            <img className="item-image" src={src} alt="item" />
            <div className="info-section">
              <p className="question">{question}</p>
              <p className="question-info">{questionInfo}</p>
            </div>
          </div>

          <div className="icon-accordian">
            {tabOpen ? (
              <FaMinus className="icons" />
            ) : (
              <FaPlus className="icons" />
            )}
          </div>
        </div>
        {tabOpen ? (
          <div className="content">
            <div className="content-box">{body}</div>
          </div>
        ) : null}
      </div>
    )
  }
}
