import React , {Component} from 'react';

//styles
import './styles.scss';


export default class HelpQueries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  render() {
    const { btnText } = this.props

    return ( 
      <div className="query-container">
        <div className="value-box help-query">
          <p className="para"># QR_1234</p>
        </div>

        <div className="value-box help-date">
          <p className="para">12/03/2020</p>
        </div>

        <div className="value-box help-related">
          <p className="para">Order #OR_0013</p>
        </div>

        <div className="value-box help-desc">
          <p className="para">My delivery was delayed by 4 weeks…</p>
        </div>

        <div className="value-box help-status">
          <button 
            className={`outlines ${btnText === 'Resolved' ? 'green' : 'red'}`}
          >
            {btnText}
          </button>
        </div>
      </div>
    );
  }
}
