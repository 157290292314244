import * as Actions from '../actions/actionTypes'

const initialState = {
    authToken: '',
};

export const login = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SET_USER_AUTH_TOKEN:
            return {
                ...state,
                authToken: action.data
            };
        case Actions.CLEAR_AUTH_TOKEN:
        return "";
        default:
            return state;
    }
};
