import React, { Component } from 'react'
import { Link } from 'react-router-dom'

//components
import Header from '../../components/Header'
import HelpQueries from '../../components/help/HelpQueries'
import OfficesLocation from '../../components/help/OfficesLocation'
import HelpAccordian from '../../components/help/HelpAccordian'
import SelectBox from '../../components/formFields/SelectBox'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import Textarea from '../../components/formFields/Textarea'
import MobileHeader from '../../components/MobileHeader'

//images
import headOfficeImg from '../../assets/Images/head-office.png'
import corporateOfficeImg from '../../assets/Images/corporate.png'
import regionalOfficeImg from '../../assets/Images/regional.png'
import questionIcon from '../../assets/Images/question-icon.svg'
import chatIcon from '../../assets/Images/chat-icon.svg'
import cancelIcon from '../../assets/Images/cancel.svg'

//styles
import './styles.scss'

export default class Ledger extends Component {
  constructor(props) {
    super(props)
    this.state = {
      chatOpen: false,
      notificationBox: false,
      query: [
        {
          id: 0,
          title: 'Products',
          listInfo: '',
          selected: false,
          key: 'query',
        },
        {
          id: 1,
          title: 'Payments',
          listInfo: '',
          selected: false,
          key: 'query',
        },
        {
          id: 2,
          title: 'Invoice',
          listInfo: '',
          selected: false,
          key: 'query',
        },
        {
          id: 3,
          title: 'Commodities',
          listInfo: '',
          selected: false,
          key: 'query',
        },
      ],

      For: [
        {
          id: 0,
          title: '12 weeks',
          listInfo: '',
          selected: false,
          key: 'For',
        },
        {
          id: 1,
          title: '24 weeks',
          listInfo: '',
          selected: false,
          key: 'For',
        },
        {
          id: 2,
          title: '36 weeks',
          listInfo: '',
          selected: false,
          key: 'For',
        },
        {
          id: 3,
          title: '48 weeks',
          listInfo: '',
          selected: false,
          key: 'For',
        },
      ],
    }

    this.chatBox = this.chatBox.bind(this)
    this.chatBoxHide = this.chatBoxHide.bind(this)
    this.notificationBox = this.notificationBox.bind(this)
  }

  chatBox() {
    this.setState({ chatOpen: true })
  }

  chatBoxHide() {
    this.setState({ chatOpen: false, notificationBox: false })
  }

  notificationBox() {
    this.setState({ notificationBox: true })
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].className = 'page-help'
    window.scrollTo(0, 0)
  }

  render() {
    const { query, For, chatOpen, notificationBox } = this.state

    return (
      <div className="help">
        <div className="main-header-section">
          <Header />
        </div>

        <MobileHeader />

        <div className="search-section">
          <h1 className="hello">Hello, how can we help?</h1>

          <p className="headline">
            Find a solution in frequently asked queries or directly get in touch
            with us.{' '}
          </p>

        </div>

        <HelpAccordian />

        <div className="query-section">
          <div className="mb-5">
            <h4 className="heading">Your Flagged Issues and Queries</h4>

            <div className="heading-section">
              <p className="main-heading help-query">Query no.</p>
              <p className="main-heading help-date">Date of Query</p>
              <p className="main-heading help-related">Query related to</p>
              <p className="main-heading help-desc">Description</p>
              <p className="main-heading help-status">status</p>
            </div>

            <HelpQueries btnText="Resolved" />

            <HelpQueries btnText="Pending" />

            <HelpQueries btnText="Resolved" />

            <HelpQueries btnText="Resolved" />
          </div>

          <div className="offices-section">
            <h4 className="heading">We’re available at</h4>

            <div className="offices">
              <OfficesLocation
                src={headOfficeImg}
                name="Head office"
                address="B-83, Housing Colony, Lohiya Nagar, Kankarbagh, Patna-800020
                Landmark: PanchShiv Mandir"
              />

              <OfficesLocation
                src={corporateOfficeImg}
                name="Corporate office"
                address="DeHaat, Unit number 619, 6th floor, DLF Star Tower, Sector 30, Gurugram 122001"
              />

              <OfficesLocation
                src={regionalOfficeImg}
                name="Regional office"
                address="DeHaat, Lahartara Crossing, Above Kheer Sagar Sweets, Lahartara, Varanasi (UP)"
              />
            </div>
          </div>
        </div>

        <div className="chat-section">
          <div className="info-box" onClick={this.chatBox}>
            <p className="heading">Didn’t find what you were looking for?</p>
            <span>
              <img
                src={questionIcon}
                alt="question-icon"
                className="question-icon"
              />
            </span>
          </div>

          {chatOpen ? (
            <div className="mobile-chat-box">
              <div className="backdrops"></div>

              <div className="chat-box">
                <div className="content-box">
                  <div className="box">
                    <img src={chatIcon} alt="chat-icon" />
                    <p className="chat-heading">
                      Get in touch and we’ll respond to you asap
                    </p>
                  </div>
                  <Link to="#" onClick={this.chatBoxHide}>
                    <img src={cancelIcon} alt="cancel-icon" />
                  </Link>
                </div>

                <div className="selection">
                  <div className="variety">
                    <p className="select-value">Query related to</p>
                    <SelectBox title="Select Query" list={query} />
                  </div>

                  <div className="variety">
                    <p className="select-value">For</p>
                    <SelectBox title="Select For" list={For} />
                  </div>
                </div>

                <div>
                  <p className="select-value">Message</p>
                  <Textarea placeholder="Enter text here.." />
                </div>

                <div className="mt-3">
                  <PrimaryButton
                    btnText="Send"
                    handleClick={this.notificationBox}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>

        {notificationBox ? (
          <div className="notification-box">
            <div className="contain">
              <p className="query">Your query has been recorded with us.</p>
              <Link to="#" onClick={this.chatBoxHide}>
                <img src={cancelIcon} alt="cancel-icon" />
              </Link>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}
