import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Link, withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import isEmpty from "lodash/isEmpty";
import unionBy from "lodash/unionBy";

//components
import SidebarNavigation from '../SidebarDrawer';
import CommodityItems from '../CommodityItems';
import PrimaryButton from '../buttons/PrimaryButton';
import SelectedItems from '../SelectedItems';
import EmptyState from "../EmptyState";

//actions
import * as commodityActions from "../../utilities/Store/actions/commodity";

//utils
import LocalStorage from "../../utilities/localStorage";

//images
import cancelbutton from '../../assets/Images/cancel-circle.svg';
import layoutGrid from '../../assets/Images/layout-grid.svg';
import logo from '../../assets/Images/bitmap.png';
import emptyState from "../../assets/Images/empty-state.svg";

//icons
import {FaCaretDown} from "react-icons/fa"

//styles
import './styles.scss';

class CommodityCatelogueSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            visibleItem: true,
            popoverOpen: false,
            sidebar: false,
            selectedCommodities: this.props.requestedCommodities.map(item => item.id) || []
        };
        this.togglePopover = this.togglePopover.bind(this);
        this.handleSidebar = this.handleSidebar.bind(this);
        this.closeSidebar = this.closeSidebar.bind(this);
    }

    componentDidMount() {
        document.body.addEventListener('click', this.handleOutsideClick);
        this.fetchCommodities()
    }

    fetchCommodities = async (payload) => {
        let filters;
        if (payload && payload.filters)
            filters = payload.filters
        else
            filters = {...this.props.commodityFilters};
        let res = {}
        try {
            this.setState({loading: true})
            res = await this.props.getCommodityList(filters)
            if (res?.error === null && !isEmpty(res.data?.product_details)) {
                this.setState({
                    commodityList: res.data?.product_details,
                    loading: false,
                })
            } else
                this.setState({loading: false})
        } catch (e) {
            this.setState({loading: false})
        }
    }


    handleFilters = (filterUpdates, fetchData) => {
        this.props.updateCommodityFilters(filterUpdates)
        let filters = {...this.props.commodityFilters, ...filterUpdates}
        if (fetchData) {
            clearTimeout(this.timer)
            this.timer = setTimeout(async () => {
                this.fetchCommodities({filters})
            }, 400)
        }
    }

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    }

    handleSidebar() {
        this.setState({sidebar: !this.state.sidebar})
    }

    closeSidebar() {
        this.setState({sidebar: false})
    }

    handleCancelItem = () => {
        this.setState({
            visibleItem: false,
        });
    }

    togglePopover() {
        this.setState({popoverOpen: true})
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.handleOutsideClick);
    }

    handleOutsideClick = (e) => {
        if (e.target.value === undefined) {
            this.setState({popoverOpen: false});
        }
    }

    toggleSelectedCommodity = (id) => {
        let operation = ''
        let {selectedCommodities} = this.state;
        if (selectedCommodities?.includes(id)) {
            let index = selectedCommodities?.indexOf(id)
            if (index > -1) {
                selectedCommodities.splice(index, 1);
                operation = 'remove'
            }

        } else {
            selectedCommodities = [...selectedCommodities, id];
            operation = 'add'
        }

        this.setState({
            selectedCommodities,
        }, () => this.updateSelectedCommodity(this.state.selectedCommodities, operation))
    };

    updateSelectedCommodity = (commodityIds, operation) => {
        let selectedCommodity = this.props.commodityList?.filter(item => commodityIds?.includes(item.id)) || []
        
        let mergedRequestedCommodities = []
        if (operation === 'add')
            mergedRequestedCommodities = unionBy(this.props.requestedCommodities, selectedCommodity, "id")
        else {
            mergedRequestedCommodities = selectedCommodity
        }
        this.props.updateRequestedCommodities(mergedRequestedCommodities)
        LocalStorage.set.selectedCommodities(JSON.stringify(mergedRequestedCommodities))
    }

    removeOrderItem = (id) => {
        if (id) {
            let selectedCommodities = this.props.requestedCommodities
            selectedCommodities = selectedCommodities?.filter(item => item.id !== id)
            this.props.updateRequestedCommodities(selectedCommodities)
        }
    }

    render() {
        const {popoverOpen, sidebar, commodityList} = this.state
        const {heading, subheading, description, handleClick} = this.props;
        let firstTwoCommodities = this.props.requestedCommodities?.slice(0, 2) || []
        let restCommodities = this.props.requestedCommodities?.slice(2) || []

        return (
            <div className="commodity-catelogue-selection">
                <Row>
                    <Col xs={12} sm={12} md={5} lg={4} xl={3}>
                        <div className="layouts">
                            <Link to="#" onClick={this.handleSidebar} className="layout-grid">
                                <img src={layoutGrid} alt="layout-grid"/>
                            </Link>

                            <Link to="#" className="layout-grid">
                                <img src={logo} alt="logo"/>
                            </Link>
                        </div>

                        <div className="main-layout">
                            <SidebarNavigation handleFilters={this.handleFilters}/>
                        </div>
                    </Col>

                    {sidebar ?
                        <div>
                            <div className="backdrops"/>
                            <SidebarNavigation handleFilters={this.handleFilters} onClick={this.closeSidebar} />
                        </div>
                        : null}

                    <Col xs={12} sm={12} md={7} lg={8} xl={9}>
                        <div className="content">
                            <h2 className="heading">{heading}</h2>
                            <p className="sub-heading">{subheading}</p>

                            <div className="mobile-items">
                                {this.props.requestedCommodities?.map(item => {
                                    return <SelectedItems
                                        key={item.id}
                                        itemName={item.name}
                                        src={item.product_image_url}
                                    />
                                })
                                }
                            </div>

                            <div className="items-section">
                                <h3 className="description">{description}</h3>
                                <div className="items-block">
                                    {
                                        !isEmpty(commodityList) 
                                        ? commodityList?.map(item => {
                                                return <CommodityItems
                                                    key={item.id}
                                                    {...item}
                                                    src={item.product_image_url}
                                                    requestedCommodities={this.props.requestedCommodities}
                                                    onSelect={this.toggleSelectedCommodity}
                                                />
                                            })
                                        : <div style={{width: '100%'}}>
                                                <EmptyState
                                                    src={emptyState}
                                                    message="No commodities found"
                                                    info=""
                                                />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <div className="bottom-info-section">
                    <div className="selection-process">
                        {this.props.requestedCommodities?.length > 0 && <div className="commodity-value">
                            <p className="selected-heading">You’ve selected</p>
                            <p className="commodities">{this.props.requestedCommodities?.length} commodities</p>
                        </div>}

                        <div className="selected-items">
                            {firstTwoCommodities.map(item => {
                                return <SelectedItems
                                    itemName={item.name}
                                    src={item.product_image_url}
                                    id={item.id}
                                    removeOrderItem={this.removeOrderItem}
                                    key={item.id}
                                />
                            })
                            }

                            {
                                restCommodities?.length > 0
                                &&
                                <div className="items-selection plus">
                                    <p className="item-name"
                                       onClick={this.togglePopover}>
                                        +{restCommodities?.length} more
                                    </p>

                                    {popoverOpen ? (
                                        <div className="popover-items">
                                            <FaCaretDown className="drop-down"/>

                                            {restCommodities?.map(item => {
                                                return <div key={item.id} className="items-selection">
                                                        <img className="item-image" src={item.product_image_url} alt={`selected-item-${item.name}`} />
                                                        <p className="item-name">{item.name}</p>

                                                        <Link to="#" onClick={() => {
                                                            this.handleCancel.bind(this)
                                                            this.removeOrderItem(item.id)
                                                        }}>
                                                            <img className="cancel" src={cancelbutton} alt="cancel-button"/>
                                                        </Link>
                                                </div>
                                            })}
                                        </div>
                                    ) : (null)}
                                </div>}
                        </div>
                    </div>

                    <PrimaryButton
                        btnText="Continue"
                        handleClick={handleClick}
                    />
                </div>
            </div>
        )
    }
}

const connectedComponent = connect(
    ({commodity}) => ({
        commodityList: commodity.list,
        commodityFilters: commodity.filters,
        requestedCommodities: commodity?.requestedCommodities,
    }),
    dispatch => bindActionCreators({...commodityActions}, dispatch)
)(CommodityCatelogueSelection)

export default withRouter(connectedComponent)
