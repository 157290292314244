import React, { useEffect } from "react"
import { BrowserRouter, Switch } from "react-router-dom"
import DashboardContainer from "../dashboardContainer"
import { Provider } from "react-redux"
import store from "../utilities/Store"
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist"
import ProtectedRoute from "./protectedRoute"

import { useKeycloak } from "@react-keycloak/web"

const Router = () => {
  const persistor = persistStore(store)
  const { initialized, keycloak } = useKeycloak()

  if (!initialized) {
    return <h3>Loading ... !!!</h3>
  }

  // To catpure session of the authenticated users only
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.__insp = window.__insp || []
      window.__insp.push(["wid", 37064042])
      const ldinsp = function () {
        if (typeof window.__inspld != "undefined") return
        window.__inspld = 1
        const insp = document.createElement("script")
        insp.type = "text/javascript"
        insp.async = true
        insp.id = "inspsync"
        insp.src =
          ("https:" === document.location.protocol ? "https" : "http") +
          "://cdn.inspectlet.com/inspectlet.js?wid=37064042&r=" +
          Math.floor(new Date().getTime() / 3600000)
        const x = document.getElementsByTagName("script")[0]
        x.parentNode.insertBefore(insp, x)
      }
      setTimeout(ldinsp, 0)
    }
  }, [])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (
      initialized &&
      keycloak &&
      typeof window !== "undefined" &&
      window.gtag != null
    ) {
      keycloak
        .loadUserInfo()
        .then((user) => {
          window.gtag("set", { user_id: user.preferred_username })
        })
        .catch((err) => console.error(err))
    }
  }, [initialized, keycloak])

  return (
    <Provider store={store}>
      <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
        <BrowserRouter>
          <Switch>
            <ProtectedRoute path="/" component={DashboardContainer} />
          </Switch>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

export default Router
