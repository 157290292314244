import React , { Component } from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";

//component
import RadioButton from '../../RadioButton';
import SelectBox from '../../formFields/SelectBox';
import DatePicker from '../../formFields/DatePicker';

//utils
import {getSelectBoxList} from '../../../utilities/helpers'

//styles
import './styles.scss';

class DeliveryDetails extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            deliverDetails: {
                orderPickUpType: "selfpickup",
                deliveryType: "onetime",
            }
        };
    }
    
    setOrderPickUpType = (value) => {
        const deliverDetails = Object.assign({}, this.state.deliverDetails);
        deliverDetails.orderPickUpType = value
        this.setState({deliverDetails}, this.props.handleChange(this.state.deliverDetails))
        
    }
    
    setDeliveryType = (value) => {
        const deliverDetails = Object.assign({}, this.state.deliverDetails);
        deliverDetails.deliveryType = value
        this.setState({deliverDetails}, ()=>this.props.handleChange(this.state.deliverDetails))
        
    }
    
    setAddress = (value) => {
        const deliverDetails = Object.assign({}, this.state.deliverDetails);
        deliverDetails.address = value
        this.setState({deliverDetails}, ()=>this.props.handleChange(this.state.deliverDetails))
    }
    
    setDate = (value) => {
        const deliverDetails = Object.assign({}, this.state.deliverDetails);
        deliverDetails.deliveryEndDate = value
        this.setState({deliverDetails}, ()=>this.props.handleChange(this.state.deliverDetails)
        )
    }
    
    setWarehouse = (value) => {
        const deliverDetails = Object.assign({}, this.state.deliverDetails);
        deliverDetails.warehouseRequest = value
        this.setState({deliverDetails}, ()=>this.props.handleChange(this.state.deliverDetails))
        
    }
    
    render() {
        const { deliverDetails } = this.state;
        const { addresses } = this.props;
        return ( 
            <div className="delivery-details">
                <div className="mb-4">
                    <p className="status">Where do you want the order delivered?</p>
                    
                    <div className="flex-box">
                        <RadioButton
                            value="selfpickup"
                            selected={deliverDetails.orderPickUpType}
                            text="Self-Pickup"
                            onChange={this.setOrderPickUpType}
                        />
                    
                        <RadioButton
                            value="deliverytoyou"
                            selected={deliverDetails.orderPickUpType}
                            text="Delivery to you"
                            onChange={this.setOrderPickUpType}
                        />
                    </div>
                </div>
                
                {deliverDetails.orderPickUpType==="deliverytoyou"
                    ?<div className="address">
                        <p className="status">Delivery Address</p>
                        <SelectBox
                            attribute="Select Address"
                            list={getSelectBoxList(addresses)}
                            onSelect={this.setAddress}
                        />
                    </div>
                    :<></>
                }
            
            <div className="mb-4">
                <p className="status">Delivery type</p>
                <div className="flex-box">
                    <RadioButton
                        value="onetime"
                        selected={deliverDetails.deliveryType}
                        text="One-time delivery"
                        onChange={this.setDeliveryType}
                    />
                    
                    <RadioButton
                        value="installments"
                        selected={deliverDetails.deliveryType}
                        text="Deliver in installments"
                        onChange={this.setDeliveryType}
                    />
                </div>
            </div>
            {deliverDetails.deliveryType==="installments"
                ?<div className="delivery-end-date">
                    <DatePicker updateDate={this.setDate} placeholder="Deliver By"/>
                </div>
                :<></>
            }
        </div>
        );
    }
}

export default withRouter(connect(({user}) => ({...user}))(DeliveryDetails))
