import React, { Component } from "react"

//component
import Accordian from "../../Accordian"
import Textarea from "../../formFields/Textarea"

//images
import specialRequest from "../../../assets/Images/special-request.svg"

export default class SpecialRequest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
    }
    this.trackAccordionClickEvent = this.trackAccordionClickEvent.bind(this)
  }

  trackAccordionClickEvent() {
    if (typeof window !== "undefined" && window.gtag != null) {
      window.gtag("set", "event", { action: "special_request" })
    }
  }

  handleChange = (value) => {
    this.setState({ value }, () => this.props.handleChange(value))
  }

  render() {
    return (
      <Accordian
        src={specialRequest}
        question="Looking for any other special request?"
        questionInfo="We’ve got you covered! We assure for the maximum what we can for you."
        heading="Any Special Request"
        handleChange={(value) => this.handleChange(value)}
        onClick={this.trackAccordionClickEvent}
        body={
          <Textarea
            placeholder="Describe special request.."
            handleChange={this.handleChange}
          />
        }
      />
    )
  }
}
