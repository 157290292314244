import React from "react";

export const renderPerishableIcon = (parentCategory, listOpen) => {
    return (
        parentCategory?.toLowerCase() === 'non-perishable' ?
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                <g fill="none" fillRule="evenodd">
                    <g>
                        <g transform="translate(-40 -111) translate(40 111)">
                            <circle className="item-circle"
                                    style={listOpen ? {fill: '#ffffff'} : null}
                                    cx="20" cy="20" r="20"/>
                            <path className="item-image"
                                  style={listOpen ? {fill: '#a1cc37'} : null}
                                  fillRule="nonzero"
                                  d="M29.942 13.544c-.08-1.891-1.595-3.406-3.486-3.486l-1.691-.052c-3.285-.147-6.126 2.265-6.513 5.53-.131 1.442-1.274 2.585-2.716 2.716-3.265.387-5.677 3.229-5.53 6.513l.052 1.691c.08 1.891 1.595 3.406 3.486 3.486l1.691.052c3.284.147 6.126-2.265 6.513-5.53.131-1.442 1.274-2.585 2.716-2.716 3.265-.387 5.677-3.228 5.53-6.513l-.052-1.691zm-14.94 11.454c-.46 0-.834-.373-.834-.833 0-.46.373-.833.834-.833.46 0 .833.373.833.833 0 .46-.373.833-.833.833zm1.666 2.5c-.46 0-.833-.373-.833-.833 0-.46.373-.833.833-.833.46 0 .833.372.833.833 0 .46-.373.833-.833.833zm1.666-2.5c-.46 0-.833-.373-.833-.833 0-.46.373-.833.833-.833.46 0 .833.373.833.833 0 .46-.373.833-.833.833zm4.998-8.33c-.46 0-.833-.373-.833-.833 0-.46.373-.833.833-.833.46 0 .833.373.833.833 0 .46-.373.833-.833.833zm1.666 2.499c-.46 0-.833-.373-.833-.833 0-.46.373-.833.833-.833.46 0 .833.373.833.833 0 .46-.373.833-.833.833zm1.666-2.5c-.46 0-.833-.372-.833-.832 0-.46.373-.833.833-.833.46 0 .834.373.834.833 0 .46-.373.833-.834.833z"/>
                        </g>
                    </g>
                </g>
            </svg>
            : <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 48 48">
                <g fill="none" fillRule="evenodd">
                    <g>
                        <g transform="translate(-50 -473) translate(50 473)">
                            <circle style={listOpen ? {fill: '#ffffff'} : null} cx="24" cy="24" r="24"
                                    className="item-circle"/>
                            <path style={listOpen ? {fill: '#a1cc37'} : null} className="item-image"
                                  fillRule="nonzero"
                                  d="M31.23 22.805c-.41-.908-1.03-1.705-1.81-2.323-.965-.794-2.332-1.845-3.413-2.592L26.97 15l-3.133 1.119L20.705 15l.963 2.89c-1.081.747-2.448 1.798-3.413 2.592-.78.618-1.401 1.415-1.81 2.323-.798 1.666-.506 3.65.738 5.016 1.207 1.342 2.937 2.093 4.742 2.06h3.825c1.804.033 3.535-.718 4.742-2.06 1.243-1.366 1.535-3.35.738-5.016zm-8.176 10.208c0 .433.35.784.783.784.433 0 .784-.351.784-.784v-1.566h-1.567v1.566zm-3.092-.247c-.089.265-.03.558.156.767.186.21.47.304.744.248.274-.056.497-.254.586-.52l.604-1.814h-1.65l-.44 1.319zm7.311-1.319h-1.65l.604 1.814c.088.266.312.464.586.52.274.056.557-.039.743-.248.186-.21.245-.502.157-.767l-.44-1.319z"/>
                        </g>
                    </g>
                </g>
            </svg>
    )
}
