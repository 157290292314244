import axios from "axios"
import keycloak from "../keycloak/config"

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 12000,
})

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const { token } = keycloak
    if (token) {
      config.headers.common.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

axiosInstance.defaults.headers.post["Content-Type"] = "application/json"

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error)
)

export { axiosInstance }
