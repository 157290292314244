import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import { ReactKeycloakProvider } from "@react-keycloak/web"

import keycloak from "./utilities/keycloak/config"
import "./styles/App.scss"
import Router from "./routes"

function App() {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: "login-required", checkLoginIframe: false }}
    >
      <div className="app">
        <Router />
      </div>
    </ReactKeycloakProvider>
  )
}

export default App
