import React, { Component } from "react";

//styles
import "./styles.scss";

export default class CommodityItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: this.isCommodityAlreadySelected(),
    };
  }

  isCommodityAlreadySelected = () =>
    this.props.requestedCommodities?.some((item) => item.id === this.props.id);

  toggleCheck = () => {
    this.setState({ isChecked: !this.state.isChecked });
    this.props.onSelect(this.props.id);
  };

  render() {
    const { src, name, variants, uom } = this.props;
    return (
      <div
        className="commodity-items"
        onClick={this.toggleCheck}
        style={
          this.state.isChecked
            ? { boxShadow: "0 11px 22px 0 rgba(42, 42, 40, 0.12)" }
            : null
        }
      >
        <div className="desc">
          <img className="item-image" src={src} alt={name} />

          <div className="checkbox">
            <input type="checkbox" checked={this.state.isChecked} />
            <span />
          </div>
        </div>

        <div className="info-section">
          <p className="name">{name}</p>
          <p className="weight">{getLowestPrice(variants, uom.name)}</p>
        </div>
      </div>
    );
  }
}

function getLowestPrice(variants, unitOfMeasurement) {
  if (!variants.length > 0) return "";
  let lowest = Number.POSITIVE_INFINITY;
  let temp;
  for (let i = variants.length - 1; i >= 0; i--) {
    temp = variants[i].market_price;
    if (temp < lowest) lowest = temp;
  }
  return (
    `Starting ₹${(Math.round(lowest * 100) / 100)?.toFixed(
      2
    )} per ${unitOfMeasurement?.toLowerCase()}` || lowest
  );
}
